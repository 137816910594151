import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, IconButton, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { MESSAGE_MODEL } from '../campaignContent/CampaignMessage';

import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';
import CustCard from './ActionCard';

const useStyles = makeStyles(() => ({
  addBtn: {
    backgroundColor: appStyle.bgColor2,
    color: appStyle.txtColor2,
    '&:hover': {
      opacity: 0.9,
    },
  },
}));

const ActionCreator = ({
  loadFieldsAndContent,
  rmTypeId,
  rmId,
  actionTypeId,
  goToCmpMsgStep,
  lang,
}) => {
  const classes = useStyles();

  return (
    <CustCard title={utils.getLang(lang.title[actionTypeId])}>
      <Paper style={{ height: '200px', width: '160px', display: 'inline-block' }}>
        <Typography
          style={{
            color: appStyle.txtColor1,
            wordWrap: 'normal',
            padding: '8px',
          }}
        >
          {utils.getLang(lang.createText[actionTypeId])}
        </Typography>

        <IconButton
          onClick={e => {
            e.preventDefault();
            goToCmpMsgStep(MESSAGE_MODEL, { rmTypeId, rmId, actionTypeId, loadFieldsAndContent });
          }}
          className={classes.addBtn}
          size="medium"
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </CustCard>
  );
};

ActionCreator.propTypes = {
  loadFieldsAndContent: PropTypes.func,
  actionTypeId: PropTypes.number.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number.isRequired,
};

ActionCreator.defaultProps = {
  loadFieldsAndContent: () => {},
};

export default ActionCreator;
