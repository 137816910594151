import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import { getCurrentRequestModelId } from 'src/reducers/campaignEditor';
import paramSelectors from 'src/selectors/parameters';

import getApiCaller from 'src/utils/apiClientCaller';
import CampaignActionItem from './CampaignActionItem';
import utils from 'src/utils/utils';
import apiClient from 'src/utils/apiClient';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';
import ActionBtn from 'src/components/common/ActionBtn';
import { groupActionTypesByCat, ActionTypeCatById } from 'src/enums/actionType';
import CustomCard from 'src/components/common/Card';

const CampaignActions = ({
  availableActionTypes,
  campaignActions,
  currentRmId,
  rmTypeId,
  loadFieldsAndContent,
  goToCmpMsgStep,
  goToEditorStep,
  editedCampaign,
}) => (
  <Fade in timeout={500}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        // width: '70%',
        // alignItems: 'stretch',
      }}
    >
      <div
        style={{
          // flex: 1,
          // border: '1px solid red',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          margin: '48px 15% 0',
          // justifyContent: 'center',
        }}
      >
        <div
          // elevation={0}
          style={{
            // margin: '20px 8px 4px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            // flexDirection: 'column',
          }}
        >
          {/* <div
            style={{
              // margin: '20px 8px 4px',
              display: 'inline-flex',
            }}
          >
            {availableActionTypes.map(actionType => (
              <CampaignActionItem
                isWritable={editedCampaign.isWritable}
                key={actionType.name}
                actionTypeId={actionType.id}
                rmId={currentRmId}
                rmTypeId={rmTypeId}
                goToCmpMsgStep={goToCmpMsgStep}
                loadFieldsAndContent={async loadContentFn => {
                  await loadFieldsAndContent(currentRmId, actionType.id)(loadContentFn);
                }}
              />
            ))}
          </div> */}
          <div
            // elevation={0}
            style={{
              // margin: '20px 8px 4px',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'stretch',
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            {// groupActionTypesByCat([...availableActionTypes, ActionType.EMAIL_COACH]).map(
            groupActionTypesByCat(availableActionTypes).map(groupedTypes => (
              <CustomCard
                title={utils.getLang(
                  `smartmessaging.campaignAction.typeTitle.${
                    ActionTypeCatById[groupedTypes[0].cat]
                  }`
                )}
                key={`${groupedTypes[0].id}group`}
                contentStyle={{
                  // margin: '20px 8px 4px',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {groupedTypes.map(actionType => (
                  <CampaignActionItem
                    isWritable={editedCampaign.isWritable}
                    key={actionType.name}
                    actionTypeId={actionType.id}
                    rmId={currentRmId}
                    rmTypeId={rmTypeId}
                    goToCmpMsgStep={goToCmpMsgStep}
                    loadFieldsAndContent={async loadContentFn => {
                      await loadFieldsAndContent(currentRmId, actionType.id)(loadContentFn);
                    }}
                  />
                ))}
              </CustomCard>
            ))}
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          <ActionBtn
            cVariant="reverse"
            // variant=""
            style={{ margin: '2px' }}
            onClick={e => {
              e.preventDefault();
              goToEditorStep(1);
            }}
          >
            {utils.getLang('smartmessaging.buttonLabel.previous')}
          </ActionBtn>
          <ActionBtn
            style={{ margin: '2px' }}
            disabled={!campaignActions || !campaignActions.length}
            onClick={e => {
              e.preventDefault();
              goToEditorStep(3);
            }}
          >
            {utils.getLang('smartmessaging.buttonLabel.next')}
          </ActionBtn>
        </div>
      </div>
    </div>
  </Fade>
);

CampaignActions.propTypes = {
  availableActionTypes: PropTypes.array.isRequired,
  campaignActions: PropTypes.array.isRequired,
  currentRmId: PropTypes.number.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  loadFieldsAndContent: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  editedCampaign: PropTypes.object.isRequired,
};

function Handler(props) {
  const {
    smParams,
    doGetApiCaller,
    wizVilleEntryActivated,
    rmTypeId,
    wizVilleNewAboTypes,
    wizVilleNewAboActivated,
    wizVilleCancelAboTypes,
    wizVilleCancelAboActivated,
  } = props;

  const loadFieldsAndContent = useCallback(
    (rmId, atId) => {
      function includeWizvilleBlock() {
        if (wizVilleNewAboActivated && wizVilleNewAboTypes.indexOf(rmTypeId) !== -1) {
          return true;
        }

        if (wizVilleCancelAboActivated && wizVilleCancelAboTypes.indexOf(rmTypeId) !== -1) {
          return true;
        }
        return false;
      }
      const fn = async loadContentFn => {
        let fields = await doGetApiCaller()(apiClient.getContentFieldsForRequestModel, [
          rmId,
          atId,
        ]);

        if (!wizVilleEntryActivated) {
          const { wizvilleentryLink, ...cleanFields } = fields;
          fields = cleanFields;
        }
        if (!includeWizvilleBlock()) {
          const { wizvilleBlock, ...cleanFields } = fields;
          fields = cleanFields;
        }
        const processedFields = contentEditorUtils.processContentFields(fields, atId, {
          headerFileId: smParams.headerStoredfileId || null,
          footerFileId: smParams.footerStoredfileId || null,
        });
        await loadContentFn(processedFields);
      };
      return fn;
    },
    [
      wizVilleNewAboTypes,
      rmTypeId,
      wizVilleNewAboActivated,
      wizVilleCancelAboTypes,
      wizVilleCancelAboActivated,
      doGetApiCaller,
      wizVilleEntryActivated,
      smParams.headerStoredfileId,
      smParams.footerStoredfileId,
    ]
  );

  return <CampaignActions {...props} loadFieldsAndContent={loadFieldsAndContent} />;
}

Handler.propTypes = {
  doGetApiCaller: PropTypes.func.isRequired,
  smParams: PropTypes.object.isRequired,
  wizVilleEntryActivated: PropTypes.bool.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  wizVilleNewAboTypes: PropTypes.array.isRequired,
  wizVilleNewAboActivated: PropTypes.bool.isRequired,
  wizVilleCancelAboTypes: PropTypes.array.isRequired,
  wizVilleCancelAboActivated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  availableActionTypes: state.campaignEditor.actions.availableActionTypes,
  currentRmId: getCurrentRequestModelId(state),
  campaignActions: state.campaignEditor.actions.campaignActions,
  smParams: paramSelectors.getCurrentParams(state),
  wizVilleEntryActivated: state.app.config.wizVilleEntryActivated,
  wizVilleNewAboActivated: state.app.config.wizVilleNewAboActivated,
  wizVilleCancelAboActivated: state.app.config.wizVilleCancelAboActivated,
  rmTypeId: state.campaignEditor.editedCampaign.requestModelTypeId,
  wizVilTypes: state.app.wizVilleRequestModels,
  wizVilleNewAboTypes: state.app.wizVilleNewAboRequestModels,
  wizVilleCancelAboTypes: state.app.wizVilleCancelAboRequestModels,
});

const actionCreators = {
  doGetApiCaller: () => getApiCaller,
};

export default connect(mapStateToProps, actionCreators)(Handler);
