// const Beantype = {
//   ACTIVITY: 0,

//   COACH: 2,

//   ROOM: 3,

//   CLUB: 16,

//   TYPEOFABO: 142,

//   PRODUCT: 233,

//   OFFER: 21,

//   SEPA_REJECTION_CODE: 17,

//   SEPA_REJECTION_CODE_GROUP: 18,

//   COUNTER_UNIT: 19,

//   CONTACT_TAG_REFERENTIAL: 20,

//   // STATE: 340, -->ENUM ??

//   // CIVILITY: 377, -->ENUM

//   // SENTMESSAGE: 693,

//   // CONTENTLOG: 687,

//   // EXECUTIONLOG: 684,

//   // REQUESTMODEL: 672,
// };

// export default Beantype;

export const EBeantype = {
  ACTIVITY: {
    id: 0,
    key: 'ACTIVITY',
    apiSentence: 'activity',
    externalFKFieldName: 'activityId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  COACH: {
    id: 2,
    key: 'COACH',
    apiSentence: 'coach',
    externalFKFieldName: 'coachId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: ({ familyName }) => familyName,
    getLabel: coach => `${coach.givenName} ${coach.familyName}`,
  },
  ROOM: {
    id: 3,
    key: 'ROOM',
    apiSentence: 'studio',
    externalFKFieldName: 'studioId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CLUB: {
    id: 16,
    key: 'CLUB',
    apiSentence: 'club',
    externalFKFieldName: 'clubId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  TYPEOFABO: {
    id: 142,
    key: 'TYPEOFABOY',
    apiSentence: 'typeofabo',
    externalFKFieldName: 'typeOfAboId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  PRODUCT: {
    id: 233,
    key: 'PRODUCT',
    apiSentence: 'product',
    externalFKFieldName: 'productId',
    getSecondaryDisplayInfo: ({ code }) => code || '**',
    getSubFieldDisplayInfo: (
      product // console.log(product.name);
    ) => product.name,
    getLabel: ({ name }) => name,
  },
  OFFER: {
    id: 21,
    key: 'OFFER',
    apiSentence: 'offer',
    externalFKFieldName: 'offerId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  SEPA_REJECTION_CODE: {
    id: 17,
    key: 'SEPA_REJECTION_CODE',
    apiSentence: 'separejectioncode',
    externalFKFieldName: 'sepaRejectionCodeId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  SEPA_REJECTION_CODE_GROUP: {
    id: 18,
    key: 'SEPA_REJECTION_CODE_GROUP',
    apiSentence: 'separejectioncodegroup',
    externalFKFieldName: 'sepaRejectionCodeGroupId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  COUNTER_UNIT: {
    id: 19,
    key: 'COUNTER_UNIT',
    apiSentence: 'counterunit',
    externalFKFieldName: 'counterUnitId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CONTACT_TAG_REFERENTIAL: {
    id: 20,
    key: 'CONTACT_TAG_REFERENTIAL',
    apiSentence: 'contacttagreferential',
    externalFKFieldName: 'contactTagReferentialId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  ZONE: {
    id: 368,
    key: 'ZONE',
    apiSentence: 'zone',
    externalFKFieldName: 'zoneId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CROSSINGPOINT: {
    id: 370,
    key: 'CROSSINGPOINT',
    apiSentence: 'crossingpoint',
    externalFKFieldName: 'crossingpointId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  ENTRY: {
    id: 369,
    key: 'ENTRY',
    apiSentence: 'entry',
    externalFKFieldName: 'entryId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CLUB_TAG_REFERENTIAL: {
    id: 400,
    key: 'CLUB_TAG_REFERENTIAL',
    apiSentence: 'clubtagreferential',
    externalFKFieldName: 'clubTagReferentialId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  COMPANY: {
    id: 372,
    key: 'COMPANY',
    apiSentence: 'company',
    externalFKFieldName: 'companyId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  USER: {
    id: 373,
    key: 'USER',
    apiSentence: 'user',
    externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ givenName, familyName }) => `${familyName} ${givenName}`,
  },
  REFERENTIAL_SUSPENSION: {
    id: 374,
    key: 'REFERENTIAL_SUSPENSION',
    apiSentence: 'referential/suspension',
    // externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_MOTIVATION: {
    id: 375,
    key: 'REFERENTIAL_MOTIVATION',
    apiSentence: 'referential/motivation',
    // externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_GOAL: {
    id: 376,
    key: 'REFERENTIAL_GOAL',
    apiSentence: 'referential/goal',
    // externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_SOURCE: {
    id: 377,
    key: 'REFERENTIAL_SOURCE',
    apiSentence: 'referential/source',
    // externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_CANCELLATION: {
    id: 378,
    key: 'REFERENTIAL_CANCELLATION',
    apiSentence: 'referential/cancellation',
    // externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CIVILITY: {
    id: 379,
    key: 'CIVILITY',
    apiSentence: 'civility',
    // externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ names }) => names[Object.keys(names)[0]],
  },
};

// STATE: 340, -->ENUM ??

// CIVILITY: 377, -->ENUM

// SENTMESSAGE: 693,

// CONTENTLOG: 687,

// EXECUTIONLOG: 684,

// REQUESTMODEL: 672,

export const getBeanTypeById = id => {
  const beantypes = Object.values(EBeantype);
  let eBeanType;
  beantypes.every(bean => {
    if (bean.id === id) {
      eBeanType = bean;
      return false;
    }
    return true;
  });
  return eBeanType;
};
