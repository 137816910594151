import { createReducer } from '@acemarke/redux-starter-kit';

import apiClient from '../utils/apiClient';
import { enqueueSnackbar } from './notify';
import utils from '../utils/utils';
import { loaderActions } from './loader';

const initialState = {};

export const mapDefaultCmpsByStoryId = defaultCmpMapById =>
  Object.keys(defaultCmpMapById).reduce((acc, defaultCmpId) => {
    const rez = { ...acc };
    if (!rez[defaultCmpMapById[defaultCmpId].storyId]) {
      rez[defaultCmpMapById[defaultCmpId].storyId] = [];
    }
    rez[defaultCmpMapById[defaultCmpId].storyId].push({
      ...defaultCmpMapById[defaultCmpId],
      id: defaultCmpId,
    });

    return rez;
  }, {});

export const getAvailableDefaultCmpList = async () => {
  const data = await apiClient.getAvailableDefaultCmpList();
  return data;
};

export const generateDefaultCampaigns = defaultCmpIdList => async dispatch => {
  // creer toutes les campagnes par defaut
  // TODO loader
  dispatch(loaderActions.setLoader(true));
  await apiClient.generateDefaultCampaigns(defaultCmpIdList);

  const recipeList = await apiClient.loadRecipes();
  dispatch({ type: 'RECEIVE_RECIPES', value: recipeList });
  const cmpList = await apiClient
    .loadCampaigns()
    .finally(() => dispatch(loaderActions.setLoader(false)));

  dispatch({
    type: 'RECEIVE_CAMPAIGNS',
    value: cmpList.map(c => ({ ...c, duplicates: JSON.parse(c.duplicates) })), // TODO Duplicated code
  });
  dispatch(
    enqueueSnackbar({
      message: `${utils.getLang('smartmessaging.notifications.defaultCampaign.createSuccess')}`,
      options: {
        variant: 'info',
      },
    })
  );
};

export const handleSelectDefaultCmp = props => defaultCmpId => {
  const { selectedCampaignList, setSelectedCampaignList } = props;
  const index = selectedCampaignList.indexOf(defaultCmpId);
  if (index === -1) {
    setSelectedCampaignList(selectedCampaignList.concat(defaultCmpId));
  } else {
    const listToSplice = [...selectedCampaignList];
    listToSplice.splice(index, 1);
    setSelectedCampaignList(listToSplice);
  }
};

export default createReducer(initialState, {});
