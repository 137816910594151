import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CascadingMenuItem from 'src/components/common/CascadingMenuItem';
import utils from 'src/utils/utils';
// import massActionSelectors from 'src/selectors/massAction';

const mapCriterias = (availaibleCriterias, selectCriteria, isWritable, selectedCriterias) => {
  // selectedCriterias : tous les criteres selectionnes appartenant au GroupType (props.groupName)
  // availaibleCriterias : tous les criteres disponibles appartenant au GroupType (props.groupName)
  // retrive allGroupedAvailaibleCrit
  // TODO : describe FROM => TO data
  const availableSubGroupFields = availaibleCriterias.filter(
    c => !!c.parentDescriptor && c.parentDescriptor.descriptors.length > 1
  ); // tous les descripteurs de champ groupé de ce GroupType, sauf ceux qui n'ont qu'un champ

  // build a list of all descriptors of type 'GROUP' from GROUPedFieldList (availableSubGroupFields) parentdescriptors.
  const availableGroupFieldsDescriptors = availableSubGroupFields
    .map(subField => subField.parentDescriptor)
    // add only if descriptor is not already in list
    .reduce(
      (parentDescriptors, parentDescriptor) =>
        parentDescriptors.map(p => p.key).indexOf(parentDescriptor.key) !== -1
          ? parentDescriptors
          : parentDescriptors.concat(parentDescriptor),
      []
    );

  const selectedCriteriaKeys = Object.keys(selectedCriterias);

  // list des fieldsKeys de champ GROUP présents dans la sélection
  const selectedGroupFieldKeys = availableGroupFieldsDescriptors
    .map(c => c.key)
    .filter(agfkey => selectedCriteriaKeys.indexOf(agfkey) !== -1);

  // build subFields map
  // map of selectedSubGroupFields by GROUPfieldKey
  const groupSubfieldsMapByGroupFieldKey = availableGroupFieldsDescriptors.reduce(
    (result, groupFieldDescriptor) =>
      selectedGroupFieldKeys.indexOf(groupFieldDescriptor.key) !== -1
        ? {
            ...result,
            [groupFieldDescriptor.key]: availableSubGroupFields.filter(
              sub => sub.parentDescriptor.key === groupFieldDescriptor.key
            ),
          }
        : result,
    {}
  );

  // groupedAvailableCriterias :
  // on prépare une liste avec les sous champs GROUP 'regroupés' s'ils existent déjà dans la sélection :
  // pour chaque occurence sélectionnée, un objet groupe est créé, et contiendra une liste de chaque sous champ correspondant. Cet objet est ajouté à la liste.
  // si NON,(pas sélectionné), chaque sous champ est rajouté tel quel à la liste.
  const criteriaMenuItems = availaibleCriterias
    .filter(
      // si c n'a pas de parentDescriptor ou si le champ GROUP n'est pas sélectionné :(=si le champ n'est pas un groupe OU s'il n'est pas sélectionné(peut etre un group, non sélectionné))
      c =>
        !c.parentDescriptor ||
        c.parentDescriptor.descriptors.length < 2 || // les champ GROUP qui n'ont qu'un champ
        selectedGroupFieldKeys.indexOf(c.parentDescriptor.key) === -1
    )
    .map(c => ({
      ...c,
      onClick: isWritable
        ? e => {
            e.preventDefault();
            selectCriteria(c);
          }
        : e => {
            e.preventDefault();
          },
      label: utils.getLang(`smartmessaging.massAction.filterLabel.${c.key}`),
    }));

  selectedGroupFieldKeys.forEach(groupFieldKey => {
    const groupValues = selectedCriterias[groupFieldKey].criteriaValues;

    criteriaMenuItems.push({
      subMenuItems: groupSubfieldsMapByGroupFieldKey[groupFieldKey].map(i => ({
        ...i,
        onClick: isWritable
          ? e => {
              e.preventDefault();
              selectCriteria(i);
            }
          : e => {
              e.preventDefault();
            },
        label: utils.getLang(`smartmessaging.massAction.filterLabel.${i.key}`),
      })),
      label: utils.getLang('smartmessaging.massAction.newGroupedFilter'),
    });

    Object.keys(groupValues).forEach(uniqueId => {
      criteriaMenuItems.push({
        subMenuItems: groupSubfieldsMapByGroupFieldKey[groupFieldKey].map(i => ({
          ...i,
          onClick() {
            selectCriteria({ ...i, groupValueUniqueId: uniqueId });
          },
          label: utils.getLang(`smartmessaging.massAction.filterLabel.${i.key}`),
        })),
        label: utils.stringFormat(utils.getLang('smartmessaging.massAction.addToGroupedFilter'), [
          selectedCriterias[groupFieldKey].groupDisplayNames[uniqueId],
        ]),
      });
    });
  });

  return criteriaMenuItems;
};

const CriteriaMenu = ({
  availaibleCriterias,
  selectCriteria,
  label,
  isWritable,
  selectedCriterias,
}) => (
  <CascadingMenuItem
    caption={label}
    menuItems={mapCriterias(availaibleCriterias, selectCriteria, isWritable, selectedCriterias)}
  />
);

CriteriaMenu.propTypes = {
  availaibleCriterias: PropTypes.array.isRequired,
  selectCriteria: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isWritable: PropTypes.bool.isRequired,
  selectedCriterias: PropTypes.object,
};
CriteriaMenu.defaultProps = {
  selectedCriterias: {},
};

const actionCreators = {
  selectCriteria: criteria => ({ type: 'ADD_CRITERIA_TO_SELECTION', value: criteria }),
};

export default connect(null, actionCreators)(CriteriaMenu);
