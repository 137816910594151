import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  FormLabel,
  Typography,
  CardContent,
  Card,
  Accordion as ExpansionPanel,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';

import { withState } from 'recompose';
import AppStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const CampaignTypeItemRender = ({ typeItem, selectCampaignType, disabledSelect, classes }) => (
  <Card
    onClick={event => {
      event.preventDefault();
      if (!disabledSelect) selectCampaignType(typeItem);
    }}
    elevation={1}
    className={classes.card}
  >
    <CardContent
      className={`${classes.content} ${!disabledSelect ? 'selectable' : ''}`}
      classes={{ root: classes.contentRoot }}
    >
      <Typography className={classes.title} variant="body2">
        {utils.getLang(`smartmessaging.requestmodel.label.${typeItem.name}`)}
      </Typography>
      <Typography className={classes.description} variant="caption">
        {utils.getLang(`smartmessaging.requestmodel.description.${typeItem.name}`)}
      </Typography>
    </CardContent>
  </Card>
);

CampaignTypeItemRender.propTypes = {
  typeItem: PropTypes.object.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabledSelect: PropTypes.bool.isRequired,
};

const CampaignTypeItem = withStyles(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    backgroundColor: AppStyle.bgColor4,
    flex: 1,
  },
  title: {
    color: AppStyle.txtColor1,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: AppStyle.txtColor4,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(CampaignTypeItemRender);

const categoryAccordioItemStyles = {
  expandedSummary: { backgroundColor: AppStyle.bgColor2 },
  summary: { color: AppStyle.txtColor3, fontSize: '0.975rem' },
};

const CategoryAccordionItemRender = ({
  categoryItem,
  expandedItem = '',
  handleExpandItem,
  selectCampaignType,
  selectable,
  classes,
}) => (
  <ExpansionPanel
    elevation={0}
    expanded={expandedItem === categoryItem.name}
    onChange={handleExpandItem(categoryItem.name)}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon className={classes.summary} />}
      classes={{ expanded: classes.expandedSummary }}
    >
      <Typography className={classes.summary}>
        {utils.getLang(`smartmessaging.campaignCategory.name.${categoryItem.name}`)}
      </Typography>
    </AccordionSummary>
    <AccordionDetails style={{ display: 'block' }}>
      <div>
        {categoryItem.requestModels.map(requestModel => (
          <CampaignTypeItem
            disabledSelect={!selectable}
            key={requestModel.id}
            typeItem={requestModel}
            selectCampaignType={selectCampaignType}
          />
        ))}
      </div>
    </AccordionDetails>
  </ExpansionPanel>
);

CategoryAccordionItemRender.propTypes = {
  classes: PropTypes.object.isRequired,
  categoryItem: PropTypes.object.isRequired,
  expandedItem: PropTypes.string.isRequired,
  handleExpandItem: PropTypes.func.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
};

const CategoryAccordionItem = withStyles(categoryAccordioItemStyles)(CategoryAccordionItemRender);

const CategoriesAccordionRender = ({
  sortedCategoryList,
  requestModelsByCategoryId,
  setExpandedItem,
  expandedItem,
  selectCampaignType,
  selectable,
}) => (
  <div style={{ overflow: 'auto' }}>
    {sortedCategoryList.map(
      category =>
        !!requestModelsByCategoryId[category.id] && (
          <CategoryAccordionItem
            key={category.id}
            categoryItem={requestModelsByCategoryId[category.id]}
            handleExpandItem={categoryName => (event, expanded) => {
              if (expanded) {
                setExpandedItem(categoryName);
              } else {
                setExpandedItem('');
              }
            }}
            expandedItem={expandedItem}
            selectCampaignType={selectCampaignType}
            selectable={selectable}
          />
        )
    )}
  </div>
);

CategoriesAccordionRender.propTypes = {
  sortedCategoryList: PropTypes.array,
  setExpandedItem: PropTypes.func,
  expandedItem: PropTypes.string,
  requestModelsByCategoryId: PropTypes.object,
  selectCampaignType: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
};

CategoriesAccordionRender.defaultProps = {
  sortedCategoryList: [],
  requestModelsByCategoryId: {},
  expandedItem: '',
  setExpandedItem: () => '',
};

const CategoriesAccordion = withState(
  'expandedItem',
  'setExpandedItem',
  () => ''
)(CategoriesAccordionRender);

const CampaignTypeRender = ({
  editedCampaign,
  requestModelByRMTypeId,
  recipesById,
  selectedType,
  requestModelsByCategoryId,
  sortedCategoryList,
  selectCampaignType,
}) => (
  <Fragment>
    <FormLabel required style={{ fontSize: '0.975rem', fontWeight: 400 }}>
      {utils.getLang('smartmessaging.campaignInfos.label.campaignType')}
    </FormLabel>
    <div style={{ margin: '8px', overflow: 'auto' }}>
      {(editedCampaign.recipeId || selectedType) && (
        <CampaignTypeItem
          disabledSelect
          selectCampaignType={selectCampaignType}
          typeItem={
            editedCampaign.recipeId
              ? requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId]
              : selectedType
          }
        />
      )}

      {!editedCampaign.recipeId && !selectedType && (
        <CategoriesAccordion
          {...{
            selectable: editedCampaign.isWritable,
            requestModelsByCategoryId,
            sortedCategoryList,
            selectCampaignType,
          }}
        />
      )}
    </div>
  </Fragment>
);

CampaignTypeRender.propTypes = {
  editedCampaign: PropTypes.object,
  recipesById: PropTypes.object,
  selectedType: PropTypes.object,
  requestModelsByCategoryId: PropTypes.object.isRequired,
  sortedCategoryList: PropTypes.array.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
};

CampaignTypeRender.defaultProps = {
  recipesById: {},
  editedCampaign: null,
  selectedType: null,
};

export default CampaignTypeRender;
