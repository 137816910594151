import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, FormControl, FormLabel } from '@material-ui/core';

import appStyle from 'src/consts/appStyle';

const styles = () => ({
  title: {
    fontSize: '1rem',
    color: appStyle.txtColor3,
    backgroundColor: appStyle.bgColor2,
    // textTransform: 'uppercase',
    padding: '0 4px',
    borderRadius: '0 0 4px 0',
  },
  // cardHeader: { backgroundColor: appStyle.bgColor2 },
  cardContent: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  action: { marginTop: 0 },
  cardHeaderTitleRoot: { padding: 0, display: 'inline-block' },
});

const GroupCriteriaBloc = ({ title, showTitle, classes, children }) => (
  <Card style={{ overflow: 'visible', border: '1px solid #f1f1f1' }} elevation={0}>
    <FormControl style={{ display: 'block' }}>
      {showTitle && (
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title,
            action: classes.action,
            root: classes.cardHeaderTitleRoot,
          }}
          title={<FormLabel style={{ fontSize: '0.875rem' }}>{title}</FormLabel>}
        />
      )}
      <CardContent className={classes.cardContent}>{children}</CardContent>
    </FormControl>
  </Card>
);

GroupCriteriaBloc.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  showTitle: PropTypes.bool.isRequired,
};

export default withStyles(styles)(GroupCriteriaBloc);
