import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import appStyle from 'src/consts/appStyle';
import withDataResolver from 'src/components/common/withDataResolver';
import apiClient from 'src/utils/apiClient';
import { getBeanTypeById } from 'src/enums/beantype';
import parameterKey from 'src/enums/parameterKey';
import utils from './utils';

const FKValueRender = ({ filter, dataList }) => (
  <Typography
    component="span"
    style={{
      color: appStyle.txtColor3,
      display: 'inline',
      fontSize: '0.815rem',
    }}
  >
    {filter.values.map(v => dataList[v]).join(', ')}
  </Typography>
);

FKValueRender.propTypes = {
  filter: PropTypes.object.isRequired,
  dataList: PropTypes.object.isRequired,
};

const FKValueData = withDataResolver({
  resolve: props => async callApi => {
    if (props.data) return props.data;
    // const rez = await callApi(apiClient.getDataList, [props.filtersByKey[props.filter.key]]);

    let resolveLabel;
    if (props.filtersByKey[props.filter.key].beantypeId) {
      const eBeantype = getBeanTypeById(props.filtersByKey[props.filter.key].beantypeId);
      resolveLabel = eBeantype.getLabel;
    } else resolveLabel = ({ name }) => name;

    let doFilter = i => i;
    let valueKey;
    switch (props.filtersByKey[props.filter.key]) {
      case parameterKey.CIVILITY: {
        valueKey = 'key';
        resolveLabel = item => utils.getCivilityLabel(item, props.currentAppLn.locale);
        const clubCountryISO = sessionStorage.getItem('club.addressCountryISO');
        doFilter = i => {
          if (!i.countryIsos) return true;
          if (i.countryIsos.indexOf(clubCountryISO) !== -1) return true;
          if (props.fieldConfig.value && props.fieldConfig.value.values) {
            let keepItem = false;
            props.fieldConfig.value.values.forEach(v => {
              if (!keepItem) keepItem = i.key === v;
            });
            return keepItem;
          }
          return false;
        };
        break;
      }
      default:
        valueKey = 'id';
    }

    const rez = await callApi(apiClient.getDataList, [props.filtersByKey[props.filter.key]]);
    return rez.filter(doFilter).reduce(
      (map, dataItem) => ({
        ...map,
        [dataItem[valueKey]]: resolveLabel(dataItem),
      }),
      {}
    );
  },

  onResolved: props => async resolvedData => {
    props.setDataList(resolvedData);
  },
  loader: false,
})(FKValueRender);

function FKValue({ filter, filtersByKey, data }) {
  const [dataList, setDataList] = useState({});
  return (
    <FKValueData
      filter={filter}
      filtersByKey={filtersByKey}
      dataList={dataList}
      setDataList={setDataList}
      data={data}
    />
  );
}

FKValue.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
  data: PropTypes.array,
};

FKValue.defaultProps = { data: null };

export default FKValue;
