import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Collapse,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

import FilterInput from 'src/components/common/filters/FilterInput';

import appStyle from 'src/consts/appStyle';

import utils from 'src/utils/utils';

const tableSortLabelStyle = {
  active: { color: appStyle.txtColor1 },
  root: {
    color: appStyle.txtColor1,
    '&$active': { color: appStyle.txtColor1, '& svg path': { color: appStyle.txtColor1 } },
  },
};

const StyledTableSortLabelRender = ({ classes, children, ...props }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...props}>
    {children}
  </TableSortLabel>
);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const StyledTableSortLabel = withStyles(tableSortLabelStyle)(StyledTableSortLabelRender);

function WithFilterSortLabel({
  column,
  addFilter,
  visibleInputs,
  showInput,
  setInputRef,
  input,
  ...props
}) {
  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <StyledTableSortLabel {...props} />
        <Tooltip title={utils.getLang('smartmessaging.tooltip.filters')}>
          <IconButton
            aria-label="filter"
            onClick={() => {
              showInput(!visibleInputs);
              if (!visibleInputs) {
                if (input && input.focus) setTimeout(() => input.focus(), 300);
              }
            }}
          >
            <FilterIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Collapse in={visibleInputs}>
        <FilterInput column={column} addFilter={addFilter} setInputRef={setInputRef} />
      </Collapse>
    </Fragment>
  );
}

WithFilterSortLabel.propTypes = {
  column: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  visibleInputs: PropTypes.bool.isRequired,
  showInput: PropTypes.func.isRequired,
  setInputRef: PropTypes.func.isRequired,
  input: PropTypes.any,
};

WithFilterSortLabel.defaultProps = { input: null };

class WithRefAndFilterSortLabel extends React.Component {
  constructor(props) {
    super(props);
    this.input = null;
    this.setInputRef = element => {
      this.input = element;
    };
  }

  render() {
    return (
      <WithFilterSortLabel {...this.props} input={this.input} setInputRef={this.setInputRef} />
    );
  }
}

function CampaignListHead(props) {
  const { order, orderBy, onRequestSort, addFilter, columsConfigs } = props;
  const isNodeMapped = utils.isNodeMapped();
  const [iVisible, showInputs] = useState(false);
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            position: 'sticky',
            top: 0,
            background: '#fafafa',
            zIndex: 1,
          }}
        />
        {columsConfigs
          .filter(c => (isNodeMapped ? true : ['networkNodeName'].indexOf(c.id) === -1))
          .map(column => (
            <TableCell
              key={column.id}
              style={{
                position: 'sticky',
                top: 0,
                background: '#fafafa',
                zIndex: 1,
              }}
            >
              {!column.filtrable && (
                <StyledTableSortLabel
                  key={column.id}
                  active={orderBy === column.id}
                  direction={order}
                  onClick={() => {
                    onRequestSort(column.id, column.sortFns || false);
                  }}
                >
                  {column.label}
                </StyledTableSortLabel>
              )}
              {column.filtrable && (
                <WithRefAndFilterSortLabel
                  visibleInputs={iVisible}
                  key={column.id}
                  active={orderBy === column.id}
                  direction={order}
                  showInput={showInputs}
                  onClick={() => {
                    onRequestSort(column.id, column.sortFns || false);
                  }}
                  column={column}
                  addFilter={addFilter}
                >
                  {column.label}
                </WithRefAndFilterSortLabel>
              )}
            </TableCell>
          ))}
        <TableCell
          style={{
            position: 'sticky',
            top: 0,
            background: '#fafafa',
            zIndex: 1,
          }}
        />
      </TableRow>
    </TableHead>
  );
}
CampaignListHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  columsConfigs: PropTypes.array.isRequired,
};

export default CampaignListHead;
