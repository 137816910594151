import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Dialog,
  Tooltip,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import CreateIcon from '@material-ui/icons/Create';
import TestIcon from '@material-ui/icons/PlayCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import utils from 'src/utils/utils';
import massActionSelectors from 'src/selectors/massAction';
import { saveContent, sendContent, resetContentEditorFlowData } from 'src/reducers/massAction';
import { loaderActions } from 'src/reducers/loader';
import appStyle from 'src/consts/appStyle';
import TestForm from 'src/components/common/TestForm';
import ActionType from 'src/enums/actionType';

const NewItems = ({ actions, classes, flowData, actionType }) => (
  <Fragment>
    <div
      style={{
        boxShadow: 'inset -0.3px 0px 0px 0px #ffffffd5',
        padding: '0 8px',
      }}
    >
      <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.createNewContent')}>
        <IconButton
          aria-label="clear"
          classes={{ disabled: classes.disabledBtn }}
          onClick={e => {
            e.preventDefault();
            actions.onCreateNewContent();
          }}
          style={{ color: appStyle.txtColor2 }}
        >
          <CreateIcon />
        </IconButton>
      </Tooltip>
    </div>
    <div
      style={{
        boxShadow: 'inset -0.3px 0px 0px 0px #ffffffd5',
        padding: '0 8px',
      }}
    >
      <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.saveActionMessage')}>
        <IconButton
          aria-label="clear"
          disabled={
            !flowData.contentValidity.isValid ||
            (!flowData.isNewContent && !flowData.hasChanges) ||
            !flowData.hasChanges
          }
          classes={{ disabled: classes.disabledBtn }}
          onClick={e => {
            e.preventDefault();
            actions.onSaveContent();
          }}
          style={{ color: appStyle.txtColor2 }}
        >
          <SaveIcon />
        </IconButton>
      </Tooltip>
    </div>
    {[ActionType.MEMBER_NOTIFICATION.id].indexOf(actionType) === -1 && (
      <div
        style={{
          boxShadow: 'inset -0.3px 0px 0px 0px #ffffffd5',
          padding: '0 8px',
        }}
      >
        <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.testContent')}>
          <IconButton
            aria-label="clear"
            classes={{ disabled: classes.disabledBtn }}
            disabled={flowData.isNewContent || flowData.hasChanges}
            onClick={e => {
              e.preventDefault();
              actions.showTestForm(true);
            }}
            style={{ color: appStyle.txtColor2 }}
          >
            <TestIcon />
          </IconButton>
        </Tooltip>
      </div>
    )}
    <div
      style={{
        padding: '0 8px',
      }}
    >
      <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.send')}>
        <IconButton
          aria-label="clear"
          classes={{ disabled: classes.disabledBtn }}
          disabled={
            (!(actionType === ActionType.MEMBER_NOTIFICATION.id) &&
              flowData.diffusionTypeIsEmpty) ||
            flowData.isNewContent ||
            flowData.hasChanges
          }
          onClick={e => {
            e.preventDefault();
            actions.onSendContent();
          }}
          style={{ color: appStyle.txtColor2 }}
        >
          <SendIcon />
        </IconButton>
      </Tooltip>
    </div>
  </Fragment>
);
NewItems.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  flowData: PropTypes.object.isRequired,
  actionType: PropTypes.number.isRequired,
};

const ActionItems = ({ actions, classes, flowData, closeMenu, isWritable }) => (
  <Fragment>
    {isWritable && (
      <MenuItem
        aria-label="clear"
        onClick={e => {
          e.preventDefault();
          closeMenu();
          actions.onCreateNewContent();
        }}
        className={classes.menuItem}
      >
        <CreateIcon style={{ marginRight: '4px' }} />
        {utils.getLang('smartmessaging.massAction.navButton.createNewContent')}
      </MenuItem>
    )}
    {isWritable && (
      <MenuItem
        disabled={
          !flowData.contentValidity.isValid ||
          (!flowData.isNewContent && !flowData.hasChanges) ||
          !flowData.hasChanges
        }
        aria-label="clear"
        onClick={e => {
          e.preventDefault();
          actions.onSaveContent();
        }}
        className={classes.menuItem}
      >
        <SaveIcon style={{ marginRight: '4px' }} />
        {utils.getLang('smartmessaging.massAction.navButton.saveActionMessage')}
      </MenuItem>
    )}
    <MenuItem
      disabled={flowData.isNewContent || flowData.hasChanges}
      aria-label="clear"
      onClick={e => {
        e.preventDefault();
        closeMenu();
        actions.showTestForm(true);
      }}
      className={classes.menuItem}
      // classes={{ disabled: classes.disabledBtn }}
    >
      <TestIcon style={{ marginRight: '4px' }} />
      {utils.getLang('smartmessaging.massAction.navButton.testContent')}
    </MenuItem>
    <MenuItem
      disabled={flowData.diffusionTypeIsEmpty || flowData.isNewContent || flowData.hasChanges}
      aria-label="clear"
      onClick={e => {
        e.preventDefault();
        closeMenu();
        actions.onSendContent();
      }}
      className={classes.menuItem}
      // classes={{ disabled: classes.disabledBtn }}
    >
      <SendIcon style={{ marginRight: '4px' }} />
      {utils.getLang('smartmessaging.massAction.navButton.send')}
    </MenuItem>
  </Fragment>
);

ActionItems.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  flowData: PropTypes.object.isRequired,
  closeMenu: PropTypes.func,
  isWritable: PropTypes.bool.isRequired,
};

ActionItems.defaultProps = { closeMenu: () => {} };

function MessageActionAppBar({
  classes,
  actionType,
  doSaveContent,
  doSendContent,
  setStep,
  goToStep,
  contentValidity,
  hasChanges,
  isNewContent,
  diffusionTypeIsEmpty,
  currentName,
  currentMassActionId,
  setLoader,
  doResetContentEditorFlowData,
  forceIsNewContent,
  clearEditedData,
  actionId,
  contentFields,
  currentCampaignAction,
  isWritable,
  currentAppLn,
}) {
  const [testFormIsVisible, showTestForm] = useState(false);

  async function onSaveContent() {
    setLoader(true);
    await doSaveContent(actionType)
      .then(() => {
        forceIsNewContent(false);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  async function onSendContent() {
    setLoader(true);
    await doSendContent(actionType, () => {
      goToStep(5, {
        campaign: { id: currentMassActionId, name: currentName },
        backToMain: () => goToStep(0),
      });
      forceIsNewContent(false);
      doResetContentEditorFlowData();
      clearEditedData();
    }).finally(() => setLoader(false));
  }

  function onCreateNewContent() {
    doResetContentEditorFlowData();
    forceIsNewContent(true);
    setStep(0);
  }

  return (
    <Fragment>
      <Dialog open={testFormIsVisible} fullWidth>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h5" color="inherit" style={{ flex: 1 }}>
              {utils.getLang('smartmessaging.campaignEditor.testAction.popupTitle')}
            </Typography>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                showTestForm(false);
              }}
              style={{ color: appStyle.txtColor2 }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: '300px',
          }}
        >
          <TestForm
            forcedFields={{ field0: 'INTEGER', ...contentFields }}
            includeWizVille
            campaignActionId={(currentCampaignAction && currentCampaignAction.id) || actionId}
            actionTypeId={actionType}
            currentAppLn={currentAppLn}
          />
        </div>
      </Dialog>

      <AppBar className={classes.appBar}>
        <Toolbar>
          <div className={classes.flex}>
            <Typography variant="h5" color="inherit">
              {utils.getLang(`smartmessaging.contentEditor.title.${actionType}`)}
            </Typography>
            <Typography variant="body2" color="inherit">
              {currentName}
              {!!currentMassActionId && ` (${currentMassActionId})`}
            </Typography>
          </div>
          <NewItems
            classes={classes}
            actionType={actionType}
            flowData={{
              contentValidity,
              isNewContent,
              hasChanges,
              diffusionTypeIsEmpty,
            }}
            actions={{ onSaveContent, onSendContent, onCreateNewContent, showTestForm }}
            isWritable={isWritable}
          />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

const styles = theme => ({
  disabledBtn: {
    color: `${appStyle.txtColor2} !important`,
    backgroundColor: appStyle.bgColor1,
    opacity: 0.7,
  },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  content: { padding: `${theme.spacing(2)}px`, height: '100%' },
  flex: {
    flex: 1,
  },
  paperFullScreen: { width: '97%', height: '97%', borderRadius: '2px' },
  actionBtn: {
    backgroundColor: appStyle.bgColor1,
    margin: `${theme.spacing(0.5)}px`,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  menuItem: {
    color: appStyle.txtColor3,
    display: 'flex',
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    padding: '4px',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '240px',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
});

MessageActionAppBar.propTypes = {
  actionType: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  doSaveContent: PropTypes.func.isRequired,
  doSendContent: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isNewContent: PropTypes.bool.isRequired,
  diffusionTypeIsEmpty: PropTypes.bool.isRequired,
  currentName: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  doResetContentEditorFlowData: PropTypes.func.isRequired,
  forceIsNewContent: PropTypes.func.isRequired,
  clearEditedData: PropTypes.func.isRequired,
  contentFields: PropTypes.object,
  actionId: PropTypes.number,
  currentMassActionId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  currentCampaignAction: PropTypes.object,
  contentValidity: PropTypes.object.isRequired,
  currentAppLn: PropTypes.object.isRequired,
};

MessageActionAppBar.defaultProps = {
  contentFields: {},
  actionId: null,
  currentCampaignAction: null,
};

const mapStateToProps = state => ({
  editor: state.massAction.editor,
  objectEditor: state.massAction.objectEditor,
  contentValidity: state.massAction.contentValidity,
  hasChanges: state.massAction.contentHasChanges,
  isNewContent: massActionSelectors.isNewContent(state),
  diffusionTypeIsEmpty: !state.massAction.selectedDiffusionType,
  currentName: state.massAction.currentName,
  currentMassActionId: state.massAction.currentMassActionId,
  massActionRm: state.massAction.requestModel,
  contentFields: massActionSelectors.getContentFields(state),
  currentCampaignAction: state.massAction.currentCampaignAction,
  currentAppLn: state.app.currentLanguage,
});

export default connect(mapStateToProps, {
  doResetContentEditorFlowData: resetContentEditorFlowData,
  doSendContent: sendContent,
  doSaveContent: saveContent,
  setLoader: visible => loaderActions.setLoader(visible),
  forceIsNewContent: force => ({
    type: 'SET_FORCE_IS_NEW_CONTENT',
    value: force,
  }),
  clearEditedData: () => ({
    type: 'CLEAR_EDITED_DATA',
  }),
})(withStyles(styles)(MessageActionAppBar));
