import utils from 'src/utils/utils';

const filterFnsByType = {
  DEFAULT: (itemValue, filter) => itemValue === filter.value,
  TEXT: (itemValue, filter) => itemValue.toLowerCase().includes(filter.value.toLowerCase()),
  NUMBER: (itemValue, filter) => itemValue === filter.value,
  DATE: (itemValue, filter) => {
    if (!itemValue) return true;
    const itemValueTime = new Date(itemValue).setHours(0, 0, 0, 0);
    const start = !!filter.value[0] && new Date(filter.value[0]).setHours(0, 0, 0, 0);
    const end = !!filter.value[1] && new Date(filter.value[1]).setHours(0, 0, 0, 0);
    if (start && end) {
      return start <= itemValueTime && end >= itemValueTime;
    } else if (start) {
      return start <= itemValueTime;
    } else if (end) {
      return end >= itemValueTime;
    }
    return true;
  },
};

const filterCfgByField = {
  name: { type: 'TEXT' },
  recipeId: {
    type: 'NUMBER',
    // doTest: (itemValue, filter) => filterFnsByType.TEXT(filter.convertValue(itemValue), filter),
  },
  eventDriven: {
    type: 'TEXT',
    // doTest: (itemValue, filter) => filterFnsByType.TEXT(filter.convertValue(itemValue), filter),
  },
  actionType: { type: 'TEXT' },
  creationDate: { type: 'DATE' },
  creationUserName: { type: 'TEXT' },
  networkNodeName: { type: 'TEXT' },
  // isPlanned: {},
  // enabled: {},
};

const orTest = (list, dotest) => {
  let matches = false;
  list.forEach(e => {
    if (!matches) matches = dotest(e);
  });
  return matches;
};

const andTest = (list, dotest) => {
  let matches = true;
  list.forEach(e => {
    if (matches) matches = dotest(e);
  });
  return matches;
};

const getTestFn = pFieldName =>
  filterCfgByField[pFieldName].doTest
    ? filterCfgByField[pFieldName].doTest
    : (filterCfgByField[pFieldName].type && filterFnsByType[filterCfgByField[pFieldName].type]) ||
      filterFnsByType.DEFAULT;

const testSingleFilter = (f, item) => {
  const valueToTest = f.convertValue ? f.convertValue(item[f.key], item) : item[f.key];
  const testFn = f.type ? filterFnsByType[f.type] : getTestFn(f.key);
  return testFn(valueToTest, f);
};

const groupFilters = filters =>
  Object.values(
    filters.reduce((map, filter) => {
      if (map[filter.key]) {
        return { ...map, [filter.key]: [...map[filter.key], filter] };
      }
      return { ...map, [filter.key]: [filter] };
    }, {})
  );

/** Test AND entre groupes de filtres (regoupés par key) et test OR entre les elements de chaque groupe de filtres */
export const filterFn = filters => item =>
  andTest(groupFilters(filters), groupedFilters =>
    orTest(groupedFilters, f => testSingleFilter(f, item))
  );

export const quickFilterFn = quickFilters => item =>
  orTest(quickFilters, f => testSingleFilter(f, item));

export const getColumnData = props => [
  {
    id: 'name',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.name'),
    filtrable: true,
    filterConfig: {
      model: {
        key: 'name',
        renderLabel: () => utils.getLang('smartmessaging.campaignList.column.name'),
        renderValue: value => value,
      },
      inputConfig: {
        type: 'TEXT',
        isValid: value => !!value,
      },
    },
    sortFns: {
      asc(a, b) {
        const sortValueA = a.name.toLowerCase();
        const sortValueB = b.name.toLowerCase();

        return sortValueA < sortValueB ? -1 : 1;
      },
      desc(a, b) {
        const sortValueA = a.name.toLowerCase();
        const sortValueB = b.name.toLowerCase();
        return sortValueA > sortValueB ? -1 : 1;
      },
    },
  },
  {
    id: 'recipeId',
    filtrable: true,
    filterConfig: {
      model: {
        key: 'recipeId',
        convertValue: recipeId => (recipeId ? props.recipesById[recipeId].requestModelTypeId : -1),
        renderLabel: () => utils.getLang('smartmessaging.campaignList.column.type'),
        renderValue: value =>
          props.requestModelByRMTypeId[value]
            ? utils.getLang(
                `smartmessaging.requestmodel.label.${props.requestModelByRMTypeId[value].name}`
              )
            : utils.getLang(`smartmessaging.campaignList.pendingElement`),
      },
      inputConfig: {
        type: 'SELECT',
        isValid: value => !!value,
        getOptions() {
          return [
            {
              label: utils.getLang(`smartmessaging.campaignList.pendingElement`),
              value: -1,
            },
          ].concat(
            Object.values(props.requestModelByRMTypeId)
              .map(requestModel => ({
                label: utils.getLang(`smartmessaging.requestmodel.label.${requestModel.name}`),
                value: requestModel.requestModelTypeId,
              }))
              .sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              })
          );
        },
      },
    },
    sortFns: {
      asc(a, b) {
        if (!a.recipeId && !b.recipeId) return 0;
        if (!a.recipeId) return 1;
        if (!b.recipeId) return -1;
        if (!props.recipesById[a.recipeId]) return -1;
        if (!props.recipesById[b.recipeId]) return 1;
        const sortValueA = utils
          .getLang(
            `smartmessaging.requestmodel.label.${
              props.requestModelByRMTypeId[props.recipesById[a.recipeId].requestModelTypeId].name
            }`
          )
          .toLowerCase();
        const sortValueB = utils
          .getLang(
            `smartmessaging.requestmodel.label.${
              props.requestModelByRMTypeId[props.recipesById[b.recipeId].requestModelTypeId].name
            }`
          )
          .toLowerCase();

        return sortValueA < sortValueB ? -1 : 1;
      },
      desc(a, b) {
        if (!a.recipeId && !b.recipeId) return 0;
        if (!a.recipeId) return -1;
        if (!b.recipeId) return 1;
        if (!props.recipesById[a.recipeId]) return -1;
        if (!props.recipesById[b.recipeId]) return 1;
        const sortValueA = utils.getLang(
          `smartmessaging.requestmodel.label.${
            props.requestModelByRMTypeId[props.recipesById[a.recipeId].requestModelTypeId].name
          }`
        );
        const sortValueB = utils.getLang(
          `smartmessaging.requestmodel.label.${
            props.requestModelByRMTypeId[props.recipesById[b.recipeId].requestModelTypeId].name
          }`
        );

        return sortValueA > sortValueB ? -1 : 1;
      },
    },
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.type'),
  },
  {
    id: 'eventDriven',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.trigger'),
    filtrable: true,
    filterConfig: {
      model: {
        key: 'eventDriven',
        convertValue: (eventDriven, fullItem) => {
          if (eventDriven === true) return 'action';
          if (eventDriven === false) {
            return props.requestModelByRMTypeId[
              props.recipesById[fullItem.recipeId].requestModelTypeId
            ].periodicity;
          }
          return 'pending';
        },
        renderLabel: () => utils.getLang('smartmessaging.campaignList.column.trigger'),
        renderValue: value => {
          if (value === 'action') return utils.getLang('smartmessaging.campaignTrigger.action');
          if (value === 'day') return utils.getLang('smartmessaging.campaignTrigger.time.day');
          if (value === 'hour') return utils.getLang('smartmessaging.campaignTrigger.time.hour');
          return utils.getLang('smartmessaging.campaignList.pendingElement');
        },
      },
      inputConfig: {
        type: 'SELECT',
        isValid: value => ['action', 'hourly', 'daily'].indexOf(value) !== -1,
        getOptions() {
          return [
            { label: utils.getLang('smartmessaging.campaignTrigger.action'), value: 'action' },
            { label: utils.getLang('smartmessaging.campaignTrigger.time.day'), value: 'day' },
            { label: utils.getLang('smartmessaging.campaignTrigger.time.hour'), value: 'hour' },
            {
              label: utils.getLang('smartmessaging.campaignList.pendingElement'),
              value: 'pending',
            },
          ];
        },
      },
    },
  },
  {
    id: 'actionType',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.actionTypes'),
    filtrable: true,
    filterConfig: {
      model: {
        key: 'actionType',
        convertValue: actionType =>
          actionType || utils.getLang('smartmessaging.campaignList.pendingElement'),
        renderLabel: () => utils.getLang('smartmessaging.campaignList.column.actionTypes'),
        renderValue: value => value,
      },
      inputConfig: {
        type: 'SELECT',
        isValid: value => value.length && value.length > 0,
        getOptions() {
          return [
            { label: 'sms', value: 'sms' },
            { label: 'email', value: 'email' },
            {
              label: utils.getLang('smartmessaging.campaignList.pendingElement'),
              value: utils.getLang('smartmessaging.campaignList.pendingElement'),
            },
          ];
        },
      },
    },
  },
  {
    id: 'creationDate',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.createDate'),
    filtrable: true,
    filterConfig: {
      model: {
        key: 'creationDate',
        renderLabel: () => utils.getLang('smartmessaging.campaignList.column.createDate'),
        renderValue: value => {
          const start = !!value[0] && new Date(value[0]);
          const end = !!value[1] && new Date(value[1]);
          if (start && end) {
            return `du ${start.toLocaleDateString()} au ${end.toLocaleDateString()}`;
          } else if (start) {
            return `après le ${start.toLocaleDateString()}`;
          } else if (end) {
            return `avant le ${end.toLocaleDateString()}`;
          }
          return '';
        },
      },
      inputConfig: {
        type: 'DATE',
        isValid: value => value.length && value.length > 0 && (value[0] || value[1]),
      },
    },
  },
  {
    id: 'networkNodeName',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.networkNodeName'),
    filtrable: true,
    filterConfig: {
      model: {
        key: 'networkNodeName',
        renderLabel: () => utils.getLang('smartmessaging.campaignList.column.networkNodeName'),
        renderValue: value => value,
      },
      inputConfig: {
        type: 'TEXT',
        isValid: value => !!value,
      },
    },
  },
  {
    id: 'creationUserName',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.cretionUserName'),
    filtrable: true,
    filterConfig: {
      model: {
        key: 'creationUserName',
        renderLabel: () => utils.getLang('smartmessaging.campaignList.column.cretionUserName'),
        renderValue: value => value,
      },
      inputConfig: {
        type: 'TEXT',
        isValid: value => !!value,
      },
    },
  },
  {
    id: 'isPlanned',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.isPlanned'),
  },
  {
    id: 'enabled',
    numeric: false,
    label: utils.getLang('smartmessaging.campaignList.column.status'),
  },
];

// export const availableFilters = [{ key: 'name' }, { key: 'recipeId' }];

export default { filterFn, quickFilterFn };
