import React from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel, RadioGroup } from '@material-ui/core';
import utils from 'src/utils/utils';
import Card from 'src/components/common/Card';

import SMJTitleField from './SMJTitleField';
import Radio from 'src/components/common/Radio';
import CreatorInfos from './CreatorInfos';
import appStyle from 'src/consts/appStyle';

const RadioLabel = ({ label }) => (
  <Typography style={{ color: appStyle.txtColor3 }} variant="body2">
    {label}
  </Typography>
);
RadioLabel.propTypes = { label: PropTypes.string.isRequired };

const GroupCreator = ({
  smjModelList,
  setupPremadeGroup,
  createEmptyGroup,
  newGroupSetup,
  onNameChange,
  onGroupModelChange,
  onModeChange,
  requestModelByRMTypeId,
}) => {
  function hanleModeChange(value) {
    switch (value) {
      case 'empty':
        onGroupModelChange(null);
        break;
      case 'premade':
        onGroupModelChange(smjModelList[0]);
        break;
      default:
    }
    onModeChange(value);
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        padding: '8px 8px 8px 8px',
        flexDirection: 'column',
        // padding: '16px 24px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          // padding: '8px 8px 8px 8px',
          // flexDirection: 'column',
          // padding: '16px 24px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 2,
            margin: '8px 8px 8px 8px',
            flexDirection: 'column',
            padding: '0',
            overflow: 'hidden',
            position: 'relative',
            // margin: '24px 15% 8px',
          }}
        >
          <Card
            title={
              <Typography style={{ color: appStyle.txtColor3 }} variant="body2">
                {utils.getLang('smartmessaging.campaigns.grouped.titleField.label')}
              </Typography>
            }
            // rootStyle={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}
            // contentStyle={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto' }}
          >
            <SMJTitleField
              value={newGroupSetup.name}
              isValid={!!newGroupSetup.name}
              onChange={onNameChange}
            />
          </Card>
          <Card
            title={
              <Typography style={{ color: appStyle.txtColor3 }} variant="body2">
                {utils.getLang('smartmessaging.campaigns.grouped.createoption.label')}
              </Typography>
            }
            // rootStyle={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}
            contentStyle={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              overflow: 'auto',
              padding: '8px',
            }}
          >
            <RadioGroup
              value={newGroupSetup.mode}
              onChange={v => {
                hanleModeChange(v.target.value);
              }}
            >
              <FormControlLabel
                style={{ color: appStyle.txtColor3, fontSize: '0.9rem' }}
                control={<Radio />}
                value="empty"
                label={
                  <RadioLabel
                    label={utils.getLang(
                      'smartmessaging.campaigns.grouped.createMode.empty.optionLabel'
                    )}
                  />
                }
                labelPlacement="end"
              />

              <FormControlLabel
                control={<Radio />}
                value="premade"
                label={
                  <RadioLabel
                    label={utils.getLang(
                      'smartmessaging.campaigns.grouped.createMode.withModel.optionLabel'
                    )}
                  />
                }
                labelPlacement="end"
              />
            </RadioGroup>
          </Card>
          <div style={{ flex: 1 }} />
        </div>

        <div
          style={{
            display: 'flex',
            flex: 3,
            margin: '8px 8px 8px 8px',
            flexDirection: 'column',
            padding: '0',
            overflow: 'hidden',
            position: 'relative',
            // backgroundColor: appStyle.bgColor3,
            // margin: '24px 15% 8px',
          }}
        >
          <CreatorInfos
            newGroupSetup={newGroupSetup}
            setupPremadeGroup={setupPremadeGroup}
            createEmptyGroup={createEmptyGroup}
            requestModelByRMTypeId={requestModelByRMTypeId}
          />
        </div>
      </div>
    </div>
  );
};

GroupCreator.propTypes = {
  smjModelList: PropTypes.array.isRequired,
  setupPremadeGroup: PropTypes.func.isRequired,
  createEmptyGroup: PropTypes.func.isRequired,
  newGroupSetup: PropTypes.object.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onGroupModelChange: PropTypes.func.isRequired,
  onModeChange: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
};

export default GroupCreator;
