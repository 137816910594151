import React from 'react';
import { configureStore } from '@acemarke/redux-starter-kit';
import { Provider } from 'react-redux';
import JssProvider from 'react-jss/lib/JssProvider';
import { Jss, create } from 'jss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTheme } from '@material-ui/core/styles';
import { jssPreset, ThemeProvider } from '@material-ui/styles';

import userIsAuthenticated from './auth/userIsAuthenticated';
import userIsNotAuthenticated from './auth/userIsNotAuthenticated';
import rootReducer from '../reducers';
import Main from './main/Main';
import Logger from './auth/Logger';

import Alert from './common/Alert';
import NotFound from './common/NotFound';
import appStyle from '../consts/appStyle';
import ModalLoader from './common/ModalLoader';
import Test from './Test';
import { Overrides } from '@material-ui/core/styles/overrides';
import InvitationQRCode from './InvitationQRCodePage';

const myTheme = createTheme({
  typography: {
    fontFamily: '"Ubuntu", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiSelect: { selectMenu: { height: '1.1875em' } },
    MuiTableCell: {
      root: { padding: '4px 56px 4px 24px', fontSize: '0.815rem' },
    },
    MuiInputAdornment: { root: { height: 'unset' } },
    MuiIconButton: { root: { '&$disabled': { opacity: 0.7 } } },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: appStyle.txtColor1,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: { '&$checked': { color: appStyle.txtColor1 } },
    },
    // MuiTableCell: { root: { padding: '4px 0' } },
    MuiInput: {
      input: {
        color: appStyle.txtColor3,
        // background: appStyle.bgColor4,
        padding: '6px 2px',
        // fontSize: '14px'
      },
      underline: {
        borderBottomColor: `${appStyle.bgColor1}`,
        '&:after': {
          backgroundColor: appStyle.bgColor1,
          borderBottomColor: `${appStyle.bgColor1}`,
        },
        '&:hover:not($disabled):before': {
          backgroundColor: appStyle.bgColor1,
          opacity: 0.5,
          height: 1,
          borderBottomColor: `${appStyle.bgColor1}`,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        color: appStyle.txtColor3,
      },
      root: {
        '&$focused': {
          '& $notchedOutline': {
            borderColor: appStyle.bgColor1,
          },
        },
      },
    },
    // MuiListItem: {
    //   root: {
    //     background: appStyle.bgColor1,
    //   },
    // },
    MuiToolbar: {
      gutters: {
        '@media (min-width: 0px)': { padding: '0 8px' },
      },
    },
    MuiPickersClockPointer: {
      noPoint: { backgroundColor: appStyle.bgColor1 },
      thumb: { borderColor: appStyle.bgColor1 },
      pointer: {
        backgroundColor: appStyle.bgColor1,
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: appStyle.bgColor1,
      },
      // isSelected: {
      //   backgroundColor: appStyle.bgColor1,
      // },
    },
    MuiPickersClock: {
      pin: { backgroundColor: appStyle.bgColor1 },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appStyle.bgColor1,
      },
    },
    // MuiPickersCalendarHeader: {
    //   switchHeader: {
    //     // backgroundColor: lightBlue.A200,
    //     // color: 'white',
    //   },
    // },
    MuiPickersDay: {
      day: {
        color: appStyle.bgColor1,
      },
      // isSelected: {
      //   backgroundColor: appStyle.bgColor1,
      // },

      current: {
        color: appStyle.bgColor1,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: appStyle.bgColor1,
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: appStyle.bgColor1,
          '& + $track': {
            backgroundColor: appStyle.bgColor1,
          },
        },
      },
    },
  } as Overrides,
});

const jss: Jss & {
  options?: { createGenerateClassName: Function };
} = create({
  ...jssPreset(),
  insertionPoint: document.body,
});

let ruleCounter = 0;
jss.options.createGenerateClassName = () => (rule, sheet) => {
  ruleCounter += 1;
  let prefix = '';
  let jssId = '';

  if (sheet) {
    prefix = sheet.options.classNamePrefix;
    if (sheet.options.jss.id != null) jssId += sheet.options.jss.id;
  }
  return `${prefix}-${rule.key}${jssId && `-${jssId}`}-${ruleCounter}`;
};

const store = configureStore({ reducer: rootReducer });

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <JssProvider jss={jss}>
          <ThemeProvider theme={myTheme}>
            <Router>
              <Switch>
                <Route
                  path="/app/:clientToken"
                  component={userIsAuthenticated(props => (
                    <Main {...props} />
                  ))}
                />
                <Route
                  exact
                  path="/auth/:clientToken"
                  component={userIsNotAuthenticated(props => (
                    <Logger {...props} />
                  ))}
                />
                <Route
                  path="/invitation/:clientToken/:identificatorId/:s"
                  component={props => <InvitationQRCode {...props} />}
                />
                <Route path="/test" component={props => <Test {...props} />} />
                <Route path="/" component={NotFound} />
              </Switch>
            </Router>
            <Alert />
            <ModalLoader />
          </ThemeProvider>
        </JssProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
