import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  IconButton,
  Tooltip,
  Toolbar,
  AppBar,
  Breadcrumbs,
  ButtonBase,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import WarningIcon from '@material-ui/icons/Warning';
import SaveIcon from '@material-ui/icons/Save';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import CampaignConfigStep from 'src/enums/campaignConfigStep';
// import { editCampaign } from 'src/reducers/campaignEditor';

// import BackBtn from './../HistoryBackBtn';

const styles = () => ({
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
    textAlign: 'left',
  },
  flex: { flex: 1 },
  menuItem: {
    '&[class*="disabled"]': { color: '#ababab', opacity: 0.7 },
    color: appStyle.txtColor3,
    outline: 'none',
    cursor: 'pointer',
    // margin: '1px 0px',
    fontSize: '0.9rem',
    // borderBottom: appStyle.border1,
    display: 'block',
    '&.selected': {
      color: appStyle.txtColor1,

      // fontWeight: 450,
      // borderBottom: appStyle.border1,
    },
    '&:hover': {
      // borderBottom: `2px solid ${appStyle.bgColor2}`,
      opacity: 0.9,
    },
  },
});

function EditorAppBar({
  classes,
  editedCampaign,
  currentStep,
  goToEditorStep,
  recipeCfgLoaded,
  campaignActions,
  periods,
  requestModelByRMTypeId,
  recipesById,
  selectedType,
  visibleBtns,
  saveCampaign,
  campaignValidity,
  smartjourney,
}) {
  return (
    <AppBar className={classes.appBar}>
      <Toolbar style={{ padding: '0px 24px' }}>
        <div className={classes.flex}>
          <Typography variant="h5" color="inherit">
            {editedCampaign.name || utils.getLang('smartmessaging.campaignEditor.emptyNameTitle')}
            {!!editedCampaign.id && ` (${editedCampaign.id})`}
          </Typography>
          {(selectedType || editedCampaign.recipeId) && (
            <Typography variant="body2" color="inherit">
              {smartjourney.name}
              {' - '}
              {(editedCampaign.recipeId &&
                utils.getLang(
                  `smartmessaging.requestmodel.label.${
                    requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId]
                      .name
                  }`
                )) ||
                utils.getLang(`smartmessaging.requestmodel.label.${selectedType.name}`)}
            </Typography>
          )}
        </div>
        {visibleBtns && editedCampaign.isWritable && (
          <Fragment>
            <div
              style={{
                boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
                padding: '0 8px',
              }}
            >
              <Tooltip title={utils.getLang('smartmessaging.button.campaignEditor.save')}>
                <IconButton
                  disabled={!campaignValidity.isValid}
                  style={{ margin: '2px', color: appStyle.txtColor2 }}
                  onClick={e => {
                    e.preventDefault();
                    saveCampaign(true);
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Fragment>
        )}
      </Toolbar>
      <Breadcrumbs
        style={{ background: appStyle.bgColor4, padding: '8px 24px', flex: 1 }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <ButtonBase
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.INFORMATIONS ? 'selected' : ''
          }`}
          // classes={{ disabled: classes.disabled }}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.INFORMATIONS);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.infos')}
          </Typography>
        </ButtonBase>
        <ButtonBase
          disabled={!recipeCfgLoaded}
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.RECIPE_CONFIG ? 'selected' : ''
          }`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.RECIPE_CONFIG);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.targetedCustomers')}
          </Typography>
        </ButtonBase>
        <ButtonBase
          disabled={!(campaignActions.length || CampaignConfigStep.CONTENT_SETUP === currentStep)}
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.CONTENT_SETUP ? 'selected' : ''
          }`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.CONTENT_SETUP);
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!!editedCampaign.duplicates.length && (
              <Tooltip title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}>
                <WarningIcon style={{ color: appStyle.txtColor6 }} />
              </Tooltip>
            )}
            <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
              {utils.getLang('smartmessaging.campaignEditor.menuItem.content')}
            </Typography>{' '}
          </div>
        </ButtonBase>
        <ButtonBase
          // disabled
          disabled={
            !(CampaignConfigStep.SCHEDULING === currentStep || (!!periods && !!periods.length))
          }
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.SCHEDULING ? 'selected' : ''
          }`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.SCHEDULING);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.schedule')}
          </Typography>
        </ButtonBase>
      </Breadcrumbs>
    </AppBar>
  );
}

EditorAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  editedCampaign: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  recipeCfgLoaded: PropTypes.bool.isRequired,
  campaignActions: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  visibleBtns: PropTypes.bool.isRequired,
  saveCampaign: PropTypes.func.isRequired,
  campaignValidity: PropTypes.object.isRequired,
  smartjourney: PropTypes.object.isRequired,
};

EditorAppBar.defaultProps = {
  selectedType: null,
};

export default withStyles(styles)(EditorAppBar);
