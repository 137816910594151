import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const SMJSelectedCmpList = ({ selection, requestModelByRMTypeId }) => (
  <Fragment>
    {Object.entries(selection).map(([rmId, list]) => (
      <div
        key={rmId}
        style={{
          display: 'inline-block',
          margin: '4px',
          borderBottom: appStyle.border1,
          width: '40%',
          maxWidth: '400px',
          minWidth: '150px',
        }}
      >
        <Typography style={{ color: appStyle.txtColor4, fontWeight: 200 }}>
          {utils.getLang(`smartmessaging.requestmodel.label.${requestModelByRMTypeId[rmId].name}`)}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {list.map(cmp => (
            <Typography
              key={cmp.key}
              variant="body2"
              style={{ color: appStyle.txtColor3, fontWeight: 200 }}
            >
              {utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${cmp.key}`)}
            </Typography>
          ))}
        </div>
      </div>
    ))}
  </Fragment>
);

SMJSelectedCmpList.propTypes = {
  selection: PropTypes.object.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
};

export default SMJSelectedCmpList;
