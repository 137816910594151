import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Divider } from '@material-ui/core';

import massActionSelectors from 'src/selectors/massAction';

import MassActionCriteriaField from './MassActionCriteriaField';

import massActionUtils from 'src/reducers/massAction/utils';

const isRemovable = (criteria, criterias) => {
  const removable =
    !!criteria.model.parentDescriptor ||
    !criteria.model.mandatory ||
    Object.keys(criterias[criteria.model.key].criteriaValues).length > 1;

  return removable;
};

const SelectedCriteriaList = ({ criterias, groupName, selectionValidity, isWritable }) => (
  <Fragment>
    {Object.values(criterias).map(criteria => (
      <Fragment key={criteria.model.key}>
        {Object.entries(criteria.criteriaValues).map(([uniqueId, criteriaValue]) => (
          <MassActionCriteriaField
            key={uniqueId}
            isWritable={isWritable}
            uniqueId={uniqueId}
            groupName={groupName}
            model={criteria.model}
            value={criteriaValue}
            removable={isRemovable(criteria, criterias)}
            validity={selectionValidity.validities[uniqueId] || { isValid: true, messages: [] }}
            groupDisplayNames={criteria.groupDisplayNames}
          />
        ))}
        <Divider style={{ margin: '8px 28px 0 28px' }} />
      </Fragment>
    ))}
  </Fragment>
);

SelectedCriteriaList.propTypes = {
  criterias: PropTypes.object.isRequired,
  groupName: PropTypes.string.isRequired,
  selectionValidity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  criterias: massActionSelectors.getSelectedCriteriaGroup(state.massAction, ownProps.groupName),
  selectionValidity: massActionUtils.selectionIsValid(state.massAction.selectedCriterias),
});

export default connect(mapStateToProps, {})(SelectedCriteriaList);
