/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { emphasize } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { Paper, Typography, Chip, TextField, MenuItem } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const styles = theme => ({
  input: {
    display: 'flex',
    flex: 1,
    padding: 0,
    height: 'fit-content',
    fontSize: '0.9rem',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'auto',
    maxHeight: '200px',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    background: appStyle.bgColor1,
    color: appStyle.txtColor2,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: '0.9rem',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: '0.9rem',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    // minWidth: '50%',
    width: '100%',
    marginTop: theme.spacing(1),
    // left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  err: { background: appStyle.bgColor5 },
  selectAll: {
    border: 'none',
    backgroundColor: 'inherit',
    color: appStyle.txtColor1,
    // fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'inline-block',
    opacity: 0.8,
    '&:hover': {
      // background: appStyle.bgColor5,
      opacity: 1,
    },
  },
});

// const useStyles = makeStyles(styles);

function NoOptionsMessage(props) {
  return (
    <Typography
      color="secondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {utils.getLang('smartmessaging.emptySelectOptions')}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;
  const pInputProps = (TextFieldProps && TextFieldProps.InputProps) || {};
  const { InputProps, ...otherTextFieldProps } = TextFieldProps || {};
  return (
    <TextField
      fullWidth
      InputProps={{
        error: !!props.selectProps.error,
        inputComponent,
        inputProps: {
          className: `${classes.input} ${props.selectProps.error && classes.err}`,
          ref: innerRef,
          children,
          ...innerProps,
        },
        ...pInputProps,
      }}
      {...otherTextFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      {...props.innerProps}
      ref={props.innerRef}
      selected={props.isFocused}
      disabled={props.data.disabled}
      component="div"
      style={{
        fontWeight: props.isFocused ? 500 : 400,
        background: props.isFocused && appStyle.bgColor1,
        color: (props.isFocused && appStyle.txtColor2) || appStyle.txtColor3,
        fontSize: '0.9rem',
        padding: '4px',
        ...(() => props.data.style || {})(),
      }}
      classes={{ selected: props.selectProps.classes.menuItem }}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="secondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} style={{ color: appStyle.txtColor2 }} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {!!props.selectProps.isMulti && (
        <div style={{ textAlign: 'right' }}>
          <button
            onClick={() => {
              if (props.selectProps.selectAll)
                props.selectProps.onChange(props.selectProps.selectAll(props.selectProps.options));
              else props.selectProps.onChange(props.selectProps.options);
            }}
            className={props.selectProps.classes.selectAll}
          >
            {utils.getLang('smartmessaging.selectInput.selectAll')}
          </button>
        </div>
      )}
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const MuiReactSelect = ({
  classes,
  options,
  value,
  onChange,
  error,
  isMulti = true,
  mapValue,
  getValue,
  ...rest
}) => (
  <Select
    closeMenuOnSelect={false}
    classes={classes}
    options={options}
    components={components}
    value={mapValue ? mapValue(value, options) : value.map(v => options.find(o => o.value === v))}
    onChange={vals => {
      if (isMulti) onChange(getValue ? getValue(vals) : vals.map(v => v.value));
      else onChange(getValue ? getValue(vals) : vals && vals.value);
    }}
    placeholder={false}
    isMulti={isMulti}
    isClearable
    // inputProps={{ style: { backgroundColor: 'red' } }}
    error={!!error}
    {...rest}
  />
);

MuiReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  mapValue: PropTypes.func,
  getValue: PropTypes.func,
};
MuiReactSelect.defaultProps = {
  mapValue: null,
  getValue: null,
};

export default withStyles(styles)(MuiReactSelect);
