import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Fade } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { loadSmParams } from 'src/reducers/parameters';
import { loadImageMaxSize, loadClubInfos } from 'src/reducers/app';
import MainMenu from './MainMenu';
import MainContent from './MainContent';
import appStyle from 'src/consts/appStyle';
import Notifier from '../common/Notifier';
import withDataResolver from '../common/withDataResolver';
import NotFound from '../common/NotFound';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LocalizedUtils from '../common/LocalizedUtils';
import * as locales from 'date-fns/locale';

function Main({ classes, appHasContent, currentAppLn }) {
  const [key, setKey] = useState(0);
  const rerender = useCallback(() => {
    setKey(prev => (prev ? 0 : 1));
  }, []);
  function getPickerProviderLocale() {
    switch (currentAppLn.shortName) {
      case 'FR':
        return locales.fr;
      case 'EN':
        return locales.enUS;
      case 'ES':
        return locales.es;
      default:
        return locales.fr;
    }
  }
  return appHasContent ? (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={getPickerProviderLocale()}>
      <Fade in timeout={1000}>
        <div key={key} className={classes.root}>
          <MainMenu rerender={rerender} />
          <MainContent />
          <SnackbarProvider
            autoHideDuration={2000}
            maxSnack={3}
            classes={{
              // variantSuccess: classes.success,
              variantError: classes.error,
              // variantWarning: classes.warning,
              variantInfo: classes.variantInfo,
            }}
          >
            <Notifier />
          </SnackbarProvider>
        </div>
      </Fade>
    </MuiPickersUtilsProvider>
  ) : (
    <NotFound text="Accès non-autorisé" />
  );
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  appHasContent: PropTypes.bool.isRequired,
  currentAppLn: PropTypes.object.isRequired,
};

const styles = () => ({
  root: {
    textAlign: 'center',
    overflow: 'auto',
    display: 'flex',
    height: '100vh',
  },
  // success: { backgroundColor: 'purple' },
  // error: { backgroundColor: `${appStyle.bgColor8} !important` },
  error: { backgroundColor: `orange !important` },
  // warning: { backgroundColor: 'green' },
  variantInfo: { backgroundColor: `${appStyle.bgColor1} !important` },
});

const actionCreators = {
  doLoadSmParams: loadSmParams,
  doLoadImageMaxSize: loadImageMaxSize,
  doLoadClubInfos: loadClubInfos,
};

const resolvers = {
  resolve: props => async () => {
    await props.doLoadClubInfos();
    await props.doLoadSmParams();
    await props.doLoadImageMaxSize();
  },
  onResolved: () => async () => {},
};

const WithData = withDataResolver(resolvers)(Main);

export default connect(
  state => ({ appHasContent: state.app.appHasContent, currentAppLn: state.app.currentLanguage }),
  actionCreators
)(withStyles(styles)(WithData));
