import axios from 'axios';

import ActionType from '../enums/actionType';
import { getBeanTypeById } from '../enums/beantype';
import utils from './utils';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const simpleRequester = axios.create();

const refreshToken = async clientToken => {
  const formData = new FormData();
  formData.set('grant_type', 'refresh_token');
  formData.set('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
  formData.set('client_secret', process.env.REACT_APP_OAUTH_CLIENT_SECRET);
  formData.set(
    'refresh_token',
    JSON.parse(sessionStorage.getItem('sm-store')).security.refresh_token
  );
  formData.set('redirect_uri', `${process.env.REACT_APP_PUBLIC_URL}/auth/${clientToken}`);

  const response = await simpleRequester({
    url: `${process.env.REACT_APP_OAUTH_SERVER_URL}/${clientToken}/oauth/v2/token`,
    method: 'POST',
    data: formData,
  });
  return response.data;
};

export const doAuthentifiedReq = async config => {
  const smStore = JSON.parse(sessionStorage.getItem('sm-store'));
  const headers = {
    Authorization: `Bearer ${smStore.security.access_token}`,
    'X-User-Club-Id': `/${smStore.client}/clubs/${sessionStorage.getItem('club')}`,
    ...config.headers,
  };

  const networknode = sessionStorage.getItem('networknode');
  if (networknode)
    headers['X-User-Network-Node-Id'] = `/${smStore.client}/network_nodes/${networknode}`;

  const call = async () => {
    const rez = await axios({
      ...config,
      headers,
    });
    return rez;
  };

  const response = await call();
  return response;
};

const loadCampaigns = async mode => {
  let params = '';
  if (mode && mode.length) {
    params = '?';
    mode.forEach(m => {
      if (m) params += `mode=${m}&`;
    });
    params = params.slice(0, -1);
  }
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/campaign/enhanced${params}`,
  });

  return response.data;
};

const loadRequestModels = async mode => {
  let params = '';
  if (mode && mode.length) {
    params = '?';
    mode.forEach(m => {
      if (m) params += `mode=${m}&`;
    });
    params = params.slice(0, -1);
  }
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/requestmodel/all${params}`,
  });

  return response.data;
};

const loadRecipes = async () => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/recipe/all`,
  });
  return response.data;
};

const insertOrUpdateCampaign = async campaign => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/campaign`,
    data: campaign,
  });

  const updatedCampaign = {
    ...response.data,
    requestModelTypeId: response.data.requestModelTypeId || campaign.requestModelTypeId,
  };
  return updatedCampaign;
};

const insertOrUpdateCampaignAction = async action => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/campaignaction`,
    data: action,
  });
  return response.data;
};

const insertOrUpdateMessage = async message => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/message`,
    data: message,
  });
  return response.data;
};

const deleteCampaign = async campaignId => {
  await doAuthentifiedReq({
    method: 'delete',
    url: `${API_BASE_URL}/campaign/${campaignId}`,
  });
  return campaignId;
};

const loadRecipeParameters = async requestModelId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartmessaging/listParametersForRequestModel?requestModelId=${requestModelId}`,
  });
  // const truc = {
  //   key: 'offer',
  //   mandatory: false,
  //   type: 'LINKED_GROUP',
  //   allowedOperators: ['IN', 'NOT_IN'],
  //   javaParameter: false,
  //   groupType: null,
  //   descriptors: [
  //     {
  //       key: 'someKey',
  //       // mandatory:  false,
  //       type: 'LINKED',
  //       beantypeId: 233,
  //       // allowedOperators: [],
  //       // javaParameter: false,
  //       // groupType: null,
  //     },
  //     {
  //       key: 'offer',
  //       mandatory: true,
  //       type: 'FOREIGN_KEY',
  //       beantypeId: 21,
  //       allowedOperators: [],
  //       javaParameter: false,
  //       groupType: null,
  //     },
  //   ],
  // };
  // return [...response.data, truc];
  // const multibeanDescriptor = {
  //   key: 'multidescriptor',
  //   mandatory: false,
  //   type: 'MULTI_FK',
  //   // beantypeId: 21,
  //   allowedOperators: ['IN', 'NOT_IN'],
  //   descriptors: [
  //     {
  //       key: 'clubs',
  //       mandatory: false,
  //       type: 'FOREIGN_KEY',
  //       allowedOperators: [],
  //       javaParameter: false,
  //       groupType: null,
  //       beantypeId: 16,
  //       filters: [],
  //       formTypeId: 16,
  //     },
  //     {
  //       key: 'coaches',
  //       mandatory: false,
  //       type: 'FOREIGN_KEY',
  //       allowedOperators: [],
  //       javaParameter: false,
  //       groupType: null,
  //       beantypeId: 2,
  //       filters: [],
  //       formTypeId: 16,
  //     },
  //   ],
  //   javaParameter: false,
  //   groupType: null,
  // };
  // return [...response.data, multibeanDescriptor];
  return response.data;
};

const getSetup = async setupId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/setup/${setupId}`,
  });
  response.data.value = JSON.parse(response.data.value);
  return response.data;
};

const insertOrUpdateSetup = async setup => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/setup`,
    data: setup,
  });
  response.data.value = JSON.parse(response.data.value);
  return response.data;
};

const insertOrUpdateRecipe = async recipe => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/recipe`,
    data: recipe,
  });
  return response.data;
};

const getRecipe = async recipeId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/recipe/${recipeId}`,
  });
  return response.data;
};

const loadCampaignActions = async campaignId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/campaignaction/all?campaignId=${campaignId}`,
  });
  return response.data;
};

const deleteCampaignAction = async cmpActionId => {
  const response = await doAuthentifiedReq({
    method: 'delete',
    url: `${API_BASE_URL}/campaignaction/${cmpActionId}`,
  });
  return response.data;
};

const getMessage = async messageId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/message/${messageId}`,
  });
  return response.data;
};

const getMessageModels = async (rmId, actionTypeId) => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/messagemodel/all?requestModelId=${rmId}&actionTypeId=${actionTypeId}&includeGenerics=true`,
  });

  return response.data;
};

const getGenericMessageModels = async actionTypeId => {
  const response = await getMessageModels(38, actionTypeId);
  return response;
};

const getMessageModel = async messageModelId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/messagemodel/${messageModelId}`,
  });

  return response.data;
};

const listAvailableActionsForRequestModel = async rmId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartmessaging/listActionsAvailableByRequestModelId?requestModelId=${rmId}`,
  });
  // return [ActionType.EMAIL, ActionType.SMS, ActionType.MEMBER_NOTIFICATION];
  if (response.data.length) {
    const availableActions = response.data.map(respAction => ActionType[respAction]);
    return availableActions;
  }
  return [];
};

const getContentFieldsForRequestModel = async (requestModelId, actionTypeId) => {
  if (requestModelId) {
    const response = await doAuthentifiedReq({
      method: 'get',
      url: `${API_BASE_URL}/smartmessaging/listResultFieldsForRequestModel?requestModelId=${requestModelId}&actionTypeId=${actionTypeId}`,
    });

    // return { ...response.data, wizvilleBlock: 'WIZVILLE_ABO_BLOCK' };
    return response.data;
  }
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartmessaging/getDefaultResultField`,
  });
  return response.data;
};

const getDefaultTriggerTime = async () => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartmessaging/getDefaultTriggerTime`,
  });
  return response.data;
};

const deletePeriod = async periodId => {
  const response = await doAuthentifiedReq({
    method: 'delete',
    url: `${API_BASE_URL}/period/${periodId}`,
  });
  return response.data;
};

const getCampaign = async campaignId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/campaign/${campaignId}`,
  });
  // TODO
  // console.log(
  // 'FIX missing requestmodel id after cmp activation(not enhanced)!!!' /* response.data */
  // );
  return response.data;
};

const getPeriodsByCampaignId = async cmpId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/period/all?campaignId=${cmpId}`,
  });
  return response.data;
};

const insertOrUpdatePeriod = async period => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/period/`,
    data: period,
  });
  return response.data;
};

const get = async url => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url,
  });
  return response.data;
};

const getStoredFile = async storedFileId => {
  const response = await get(utils.link(storedFileId));

  return response;
};

const getStoredFileInfo = async storedFileId => {
  const response = await get(`${API_BASE_URL}/storedfile/${storedFileId}`);
  return response;
};

const getAppStatus = async () => {
  const response = await axios({
    url: `${API_BASE_URL}/status/`,
    method: 'GET',
  });
  return response.data;
};

const getClubInfos = async clientToken => {
  const smAppConfigResponse = await doAuthentifiedReq({
    url: `${API_BASE_URL}/clubinfo/${clientToken}`,
    method: 'GET',
  });
  const { id, actionCoActivated, ...smAppConfigValues } = smAppConfigResponse.data;
  const grootConfigValues = await doAuthentifiedReq({
    url: `${API_BASE_URL}/configuration/${clientToken}`,
    method: 'GET',
  });

  const smsHours = await doAuthentifiedReq({
    url: `${API_BASE_URL}/smartmessaging/smsHours`,
    method: 'GET',
  });

  return {
    ...smAppConfigValues,
    wizVilleNewAboActivated: smAppConfigValues.wizville_37_activated,
    wizVilleCancelAboActivated: smAppConfigValues.wizville_48_activated,
    ...grootConfigValues.data,
    defaultSmsHours: smsHours.data,
  };
};

const getMaxImageWeight = async () => {
  const response = await doAuthentifiedReq({
    url: `${API_BASE_URL}/smartmessaging/maxsize`,
    method: 'GET',
  });
  return response.data;
};

// change to uploadData (vs uploadFile) = use to save data to storedfile (not a selected file) =>usage to save action content(campaign messages)
const upload = async (data, dataType) => {
  const formData = new FormData();
  formData.append('file', new Blob([data], { type: dataType || 'text/plain' }));
  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/storedfile/fileUpload/`,
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
  return response.data;
};

const uploadFile = async file => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/storedfile/fileUpload/`,
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' } },
  });
  return response.data;
};

const activateCampaign = async (campaignId, enable) => {
  const formData = new FormData();
  formData.set('campaignId', campaignId);
  formData.set('enable', enable);

  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/smartmessaging/activateCampaign`,
    data: formData,
  });

  return response.data;
};

const checkDuplicateName = async name => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/campaign/campaignExist?campaignName=${name}`,
  });

  return response.data;
};

const getBlob = async url => {
  const response = await doAuthentifiedReq({
    responseType: 'blob',
    method: 'get',
    url,
  }).then(resp => resp.data);
  return response;
};

const getContexts = async code => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/context/${code || 'default'}`,
  });
  await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/civility/all`,
  });
  return response.data;
  // const response = await doAuthentifiedReq({
  //   method: 'GET',
  //   url: `${API_BASE_URL}/context`,
  // });
  // return {
  //   contextMap: code && code !== 'fr-FR' ? {} : response.data.contextMap,
  //   language: { locale: code || 'fr-FR' },
  // };
};

const getAvailableLanguages = async () => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/language/all`,
  });
  return response.data;
  // return response.data.map(l=>l.locale);
  // return [
  //   { locale: 'fr-FR', shortName: 'FR' },
  //   { shortName: 'ES', locale: 'es-ES' },
  //   { shortName: 'EN', locale: 'en-EN' },
  //   { shortName: 'MG', locale: 'mg-MG' },
  // ];
};
const requestLogin = async (clientToken, code) => {
  const formData = new FormData();
  formData.set('grant_type', 'authorization_code');
  formData.set('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
  formData.set('client_secret', process.env.REACT_APP_OAUTH_CLIENT_SECRET);
  formData.set('code', code);
  formData.set('redirect_uri', `${process.env.REACT_APP_PUBLIC_URL}/auth/${clientToken}`);

  const response = await simpleRequester({
    url: `${process.env.REACT_APP_OAUTH_SERVER_URL}/${clientToken}/oauth/v2/token`,
    method: 'POST',
    data: formData,
  });
  // return Promise.reject();
  return response.data;
};

const getClubs = async () => {
  const clubs = await get(`${API_BASE_URL}/club/all`);
  return clubs;
};

const getDataList = async fieldDescriptor => {
  let dataList;
  const eBeantype = getBeanTypeById(fieldDescriptor.beantypeId);
  if (eBeantype) {
    dataList = await get(`${API_BASE_URL}/${eBeantype.apiSentence}/all`);
    return dataList;
  }
  return [
    { id: 1, name: 'option1' },
    { id: 2, name: 'option2' },
    { id: 3, name: 'option3' },
    { id: 4, name: 'option4' },
    { id: 5, name: 'option5' },
  ];
};

const getFilteredDataList = async (beantypeId, filterBeantypeId, filterIdList) => {
  if (filterIdList && filterIdList.length) {
    const filterEBeantype = getBeanTypeById(filterBeantypeId);
    let dataList = [];
    let apiSentence = '';
    let apiSentenceTypeName = '';
    let apiSentenceFilterTypeName = '';
    let filterParamName = '';
    let apiSentenceWithParams = '';

    const eBeantype = getBeanTypeById(beantypeId);
    // const filterEBeantype = getBeanTypeById(beantypeId);

    apiSentenceTypeName = eBeantype.apiSentence;
    filterParamName = `${filterEBeantype.apiSentence}Ids`;
    apiSentenceFilterTypeName = `by${filterEBeantype.apiSentence
      .charAt(0)
      .toUpperCase()}${filterEBeantype.apiSentence.slice(1)}s`;

    apiSentence = `${apiSentenceTypeName}/${apiSentenceFilterTypeName}`;
    apiSentenceWithParams = `${apiSentence}?`;

    if (filterIdList.length) {
      apiSentenceWithParams += `${filterParamName}=${filterIdList[0]}`;
      filterIdList.slice(1).forEach(id => {
        apiSentenceWithParams += `&${filterParamName}=${id}`;
      });
    }

    dataList = await get(`${API_BASE_URL}/${apiSentenceWithParams}`);

    // const fulldataList = [
    //   { id: 1, name: 'prod1offer1', productId: 1 },
    //   { id: 10, name: 'prod1offer2', productId: 1 },
    //   { id: 100, name: 'prod1offer3', productId: 1 },
    //   { id: 2, name: 'prod2offer1', productId: 2 },
    //   { id: 20, name: 'prod2offer2', productId: 2 },
    //   { id: 200, name: 'prod2offer3', productId: 2 },
    //   { id: 3, name: 'prod3offer1', productId: 3 },
    //   { id: 30, name: 'prod3offer2', productId: 3 },
    //   { id: 300, name: 'prod3offer3', productId: 3 },
    // ];
    // dataList = fulldataList.filter(data => filterIdList.indexOf(data.productId) >= 0);
    return dataList;
  }
  return getDataList({ beantypeId });
};

const getWizVillRequestModelTypes = async () => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/smartmessaging/listWizVilleCampaignsAllowed`,
  });

  return response.data;
};

const getWizVilleNewAboRequestModelTypes = async () => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/smartmessaging/listWizvilleNewAboCampaignsAllowed`,
  });

  return response.data;
};

const getWizVilleCancelAboRequestModelTypes = async () => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/smartmessaging/listWizvilleCancelAboCampaignsAllowed`,
  });

  return response.data;
};

const saveSmParams = async params => {
  const response = await doAuthentifiedReq({
    method: 'PUT',
    url: `${API_BASE_URL}/parameter`,
    data: params,
  });
  return response.data;
};

const loadSmParams = async () => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/parameter/all`,
  });

  return response.data;
};

const loadMessages = async () => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/parameter/all`,
  });

  return response.data;
};

const deleteParams = async id => {
  const response = await doAuthentifiedReq({
    method: 'delete',
    url: `${API_BASE_URL}/parameter/${id}`,
  });
  return response.data;
};

const csvExport = async recipeId => {
  const response = await doAuthentifiedReq({
    method: 'post',
    url: `${API_BASE_URL}/smartmessaging/csvExport/${recipeId}`,
  });
  return response.data;
};

const testCampaignAction = async data => {
  const response = await doAuthentifiedReq({
    method: 'post',
    url: `${API_BASE_URL}/smartmessaging/testCampaignAction`,
    data,
  });
  return response.data;
};

const campaignIsWritable = async id => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/campaign/canEditCampaign/${id}`,
  });
  return response.data;
};

const getSmsHistory = async (from, to, mode) => {
  const formData = new FormData();
  formData.set('from', from);
  formData.set('to', to);

  if (mode && mode.length) {
    mode.forEach(m => {
      if (m) formData.append('mode', m);
    });
  }

  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/stats/smsHistory`,
    data: formData,
  });

  return response.data;
};

const getMailHistory = async (from, to, mode) => {
  const formData = new FormData();
  formData.set('from', from);
  formData.set('to', to);

  if (mode && mode.length) {
    mode.forEach(m => {
      if (m) formData.append('mode', m);
    });
  }

  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/stats/mailHistory`,
    data: formData,
  });
  return response.data;
};

const getNotifyHistory = async (from, to, mode) => {
  const formData = new FormData();
  formData.set('from', from);
  formData.set('to', to);

  if (mode && mode.length) {
    mode.forEach(m => {
      if (m) formData.append('mode', m);
    });
  }

  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/stats/memberNotifyHistory`,
    data: formData,
  });
  return response.data;
};

const getSentMessagesExport = async (from, to, atId) => {
  const formData = new FormData();
  formData.set('from', from);
  formData.set('to', to);
  formData.set('actionTypeId', atId);

  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/export/sentMessages`,
    data: formData,
  });
  return response.data;
};

const getSentMessageContent = async id => {
  const formData = new FormData();
  formData.set('contentId', id);

  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/stats/sentContent/${id}`,
    data: formData,
  });
  return response.data;
};

const getIdentificator = async (clientToken, identificatorId, s) => {
  const params = `clientToken=${clientToken}&identificatorId=${identificatorId}&sg=${s}`;
  const response = await axios({
    url: `${API_BASE_URL}/identificator?${params}`,
    method: 'GET',
  });
  return response.data;
};

const getClub = async id => {
  const response = await doAuthentifiedReq({
    method: 'GET',
    url: `${API_BASE_URL}/club/${id}`,
  });
  return response.data;
};

export default {
  activateCampaign,
  campaignIsWritable,
  csvExport,
  checkDuplicateName,
  deleteCampaign,
  deleteCampaignAction,
  deletePeriod,
  deleteParams,
  doAuthentifiedReq,
  get,
  getSentMessagesExport,
  getAvailableLanguages,
  getBlob,
  getContentFieldsForRequestModel,
  getContexts,
  getCampaign,
  getClubs,
  getDefaultTriggerTime,
  getGenericMessageModels,
  getMessage,
  getMessageModel,
  getMessageModels,
  getPeriodsByCampaignId,
  getDataList,
  getFilteredDataList,
  getRecipe,
  getSetup,
  getAppStatus,
  getClubInfos,
  getMaxImageWeight,
  getSentMessageContent,
  getStoredFile,
  getStoredFileInfo,
  getWizVillRequestModelTypes,
  getWizVilleNewAboRequestModelTypes,
  getWizVilleCancelAboRequestModelTypes,
  getSmsHistory,
  getMailHistory,
  getNotifyHistory,
  insertOrUpdateCampaign,
  insertOrUpdateCampaignAction,
  insertOrUpdateMessage,
  insertOrUpdateSetup,
  insertOrUpdateRecipe,
  insertOrUpdatePeriod,
  loadCampaigns,
  loadMessages,
  loadRequestModels,
  loadRecipes,
  loadCampaignActions,
  loadRecipeParameters,
  loadSmParams,
  listAvailableActionsForRequestModel,
  requestLogin,
  refreshToken,
  saveSmParams,
  testCampaignAction,
  upload,
  uploadFile,
  getIdentificator,
  getClub,
};
