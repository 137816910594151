import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography, IconButton, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';
import CustCard from './ActionCard';
import { MESSAGE_MODEL } from './CampaignMessage';

const ActionCreator = ({
  loadFieldsAndContent,
  rmTypeId,
  rmId,
  actionTypeId,
  classes,
  goToCmpMsgStep,
  lang,
}) => (
  <CustCard title={utils.getLang(lang.title[actionTypeId])}>
    <Paper style={{ height: '200px', width: '160px', display: 'inline-block' }}>
      <Typography
        style={{
          // wordBreak: 'break-word',

          color: appStyle.txtColor1,
          wordWrap: 'normal',
          padding: '8px',
        }}
      >
        {utils.getLang(lang.createText[actionTypeId])}
      </Typography>

      <IconButton
        onClick={e => {
          e.preventDefault();
          goToCmpMsgStep(MESSAGE_MODEL, { rmTypeId, rmId, actionTypeId, loadFieldsAndContent });
        }}
        className={classes.addBtn}
        // variant="fab"
        size="medium"
        // aria-label="add"
      >
        <AddIcon />
      </IconButton>
    </Paper>
  </CustCard>
);

ActionCreator.propTypes = {
  loadFieldsAndContent: PropTypes.func,
  classes: PropTypes.object.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

ActionCreator.defaultProps = {
  // openEditor: () => {},
  loadFieldsAndContent: () => {},
};

const styles = () => ({
  addBtn: {
    // zIndex: 2,
    backgroundColor: appStyle.bgColor2,
    color: appStyle.txtColor2,
    '&:hover': {
      // background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

export default withStyles(styles)(ActionCreator);
