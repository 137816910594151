import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import uuid from 'uuid';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import hUtils from './utils';
import ParameterType from 'src/enums/parameterType';
import { OperatorTypeById } from 'src/enums/operatorType';
import FKValue from './FKUsedFilterValue';
import DefaultValue from './DefaultUsedFilterValue';

const UsedFilterValue = ({ filter, filtersByKey }) => {
  switch (filter.typeId) {
    case ParameterType.IFOREIGN_KEY:
    case ParameterType.TFOREIGN_KEY:
    case ParameterType.N_FOREIGN_KEY:
      return <FKValue filter={filter} filtersByKey={filtersByKey} />;
    case ParameterType.ENUM:
      return (
        <FKValue
          filter={filter}
          filtersByKey={filtersByKey}
          data={utils.getDataListFromEnum(filtersByKey[filter.key].enumName)}
        />
      );
    default:
      return <DefaultValue filter={filter} filtersByKey={filtersByKey} />;
  }
};
UsedFilterValue.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

const UsedFilterRender = ({ filter, filtersByKey }) => (
  <div style={{ textAlign: 'left' }}>
    {filtersByKey[filter.key].groupType ? (
      <Fragment>
        <Typography
          component="span"
          // variant="body1"
          style={{
            // marginLeft: '8px',
            color: appStyle.txtColor3,
            display: 'inline',
            // opacity: '0.7',
            fontSize: '0.815rem',
          }}
        >
          {utils.getLang(
            `smartmessaging.massAction.groupFilterBy.${filtersByKey[filter.key].groupType}`
          )}
        </Typography>
        <Typography
          component="span"
          style={{
            color: appStyle.txtColor3,
            display: 'inline',
            fontSize: '0.815rem',
          }}
        >
          {utils.getLang(`smartmessaging.massAction.filterLabel.${filter.key}`)}
        </Typography>
      </Fragment>
    ) : (
      <Typography
        component="span"
        style={{
          color: appStyle.txtColor3,
          display: 'inline',
          fontSize: '0.815rem',
        }}
      >
        {utils.getLang(`smartmessaging.history.filterLabel.${filter.key}`)}
      </Typography>
    )}
    <Typography
      component="span"
      style={{
        color: appStyle.txtColor3,
        display: 'inline',
        fontSize: '0.815rem',
      }}
    >
      {utils.getLang(`smartmessaging.recipeSetupOperator.${OperatorTypeById[filter.operatorId]}`)}
    </Typography>
    <UsedFilterValue filter={filter} filtersByKey={filtersByKey} />
  </div>
);

const GroupUsedFilter = ({ filters, filtersByKey }) =>
  Object.values(filters).map(list =>
    list.map(f => <UsedFilterRender key={uuid()} filter={f} filtersByKey={filtersByKey} />)
  );

UsedFilterRender.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

const UsedFilter = ({ filter, filtersByKey }) => {
  switch (filter.typeId) {
    case ParameterType.GROUP:
    case ParameterType.CLUBS:
    case ParameterType.LINKED_GROUP:
      return (
        <GroupUsedFilter
          filters={hUtils.proceedSetup(filter.values)}
          filtersByKey={filtersByKey[filter.key].descriptors.reduce(
            (map, f) => ({ ...map, [f.key]: f }),
            {}
          )}
        />
      );
    default:
      return <UsedFilterRender filter={filter} filtersByKey={filtersByKey} />;
  }
};
UsedFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

export default UsedFilter;
