import React, { Fragment } from 'react';

import { Paper, Fade, FormLabel, TextField, FormControlLabel, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import Checkbox from 'src/components/common/Checkbox';

import IntegerField from 'src/components/common/IntegerField';
import ActionBtn from 'src/components/common/ActionBtn';
import CampaignType from '../CampaignType';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

const styles = theme => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    color: appStyle.txtColor3,
    outline: 'none',
    cursor: 'pointer',
    padding: '12px 8px',
    // textAlign: 'center',
    '&:hover': {
      background: appStyle.bgColor1,
      color: appStyle.txtColor2,
      opacity: 0.9,
    },
  },
  textField: {
    display: 'flex',
    margin: '8px',
  },
  textFieldInput: {
    color: appStyle.txtColor3,
    fontSize: '0.9rem',
  },
  textFieldInputUnderline: {
    '&:after': {
      backgroundColor: appStyle.bgColor3,
      borderBottom: 'none',
    },
    '&:before': {
      backgroundColor: appStyle.bgColor3,
      borderBottom: 'none',
    },
  },
  button: {
    backgroundColor: appStyle.bgColor1,
    margin: `${theme.spacing(0.5)}px`,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

const CampaignInfosRender = ({
  editedCampaign,
  selectedType,
  requestModelByRMTypeId,
  recipesById,
  updateEditedCampaign,
  doSaveCampaign,
  goToEditorStep,
  infosValidity,
  requestModelsByCategoryId,
  sortedCategoryList,
  selectCampaignType,
  //  recipeCfgLoaded,
  classes,
  onBack,
  getDefaultOptin,
  selectOptinType,
  showWizvilleInfos,
}) => (
  <Fade in timeout={500}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        // width: '70%',
        // alignItems: 'stretch',
      }}
    >
      <div
        style={{
          flex: 1,
          // border: '1px solid red',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          margin: '24px 15% 8px',
        }}
      >
        <Paper
          elevation={0}
          style={{
            // margin: '20px 8px 4px',
            overflow: 'hidden',
            display: 'flex',
            // flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <FormLabel
              required
              style={{ fontSize: '0.975rem', fontWeight: 400 }}
              htmlFor="cmp-name-ipt"
            >
              {utils.getLang('smartmessaging.campaignInfos.label.name')}
            </FormLabel>
            <TextField
              disabled={!editedCampaign.isWritable}
              InputProps={{
                className: classes.textFieldInput,
                id: 'cmp-name-ipt',
              }}
              className={classes.textField}
              value={editedCampaign.name}
              onChange={event => {
                updateEditedCampaign({ name: event.target.value });
              }}
            />
            {showWizvilleInfos && (
              <Fragment>
                <hr style={{ marginBottom: '8px', border: 'none' }} />
                <FormLabel required htmlFor="wizId" style={{ fontWeight: 400 }}>
                  {utils.getLang('smartmessaging.wizVille.idLabel')}
                </FormLabel>
                <IntegerField
                  disabled={!editedCampaign.isWritable}
                  InputProps={{
                    className: classes.textFieldInput,
                    id: 'wizId',
                  }}
                  className={classes.textField}
                  value={editedCampaign.wizVilleId || ''}
                  onChange={value => {
                    updateEditedCampaign({ wizVilleId: value });
                  }}
                />
                <hr style={{ marginBottom: '8px', border: 'none' }} />
                <FormLabel required htmlFor="wizBrand" style={{ fontWeight: 400 }}>
                  {utils.getLang('smartmessaging.wizVille.brandLabel')}
                </FormLabel>
                <TextField
                  disabled={!editedCampaign.isWritable}
                  style={{ marginBottom: '8px' }}
                  InputProps={{
                    className: classes.textFieldInput,
                    id: 'wizBrand',
                  }}
                  className={classes.textField}
                  value={editedCampaign.wizVilleBrand}
                  onChange={e => {
                    updateEditedCampaign({ wizVilleBrand: e.target.value });
                  }}
                />
              </Fragment>
            )}
            <hr style={{ marginBottom: '8px', border: 'none' }} />
            <CampaignType
              {...{
                editedCampaign,
                selectedType,
                requestModelByRMTypeId,
                recipesById,
                requestModelsByCategoryId,
                sortedCategoryList,
                selectCampaignType,
              }}
            />
            <hr style={{ marginBottom: '8px', border: 'none' }} />
            {(editedCampaign.recipeId || selectedType) && (
              <Fragment>
                <FormLabel
                  required
                  style={{ fontSize: '0.975rem', fontWeight: 400 }}
                  htmlFor="cmp-optin-ipt"
                >
                  {utils.getLang('smartmessaging.campaignInfos.label.optin')}
                </FormLabel>
                <TextField
                  className={classes.textField}
                  disabled={!editedCampaign.isWritable}
                  onChange={e => {
                    selectOptinType(e.target.value);
                  }}
                  select
                  style={{ flex: 1 }}
                  InputProps={{
                    className: classes.textFieldInput,
                    id: 'cmp-optin-ipt',
                  }}
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: { style: { padding: 0, margin: 0 } },
                      PopoverClasses: {
                        paper: classes.popover,
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      marginThreshold: 0,
                    },
                  }}
                  value={editedCampaign.optinType}
                >
                  {['legal', 'advertising', 'news'].map(o => (
                    <div key={o} value={o} className={classes.menuItem}>
                      {utils.getLang(`smartmessaging.diffusionType.${o}`)}
                    </div>
                  ))}
                </TextField>
                {getDefaultOptin(editedCampaign.recipeId, selectedType) !==
                  editedCampaign.optinType && (
                  <div style={{ display: 'flex' }}>
                    <WarningIcon style={{ color: appStyle.txtColor6, margin: '4px' }} />
                    <Typography
                      style={{
                        marginTop: '8px',
                        color: appStyle.txtColor3,
                      }}
                      variant="body2"
                    >
                      {`${utils.getLang(
                        'smartmessaging.campaignInfos.defaultOptinWarning'
                      )} : ${utils.getLang(
                        `smartmessaging.diffusionType.${getDefaultOptin(
                          editedCampaign.recipeId,
                          selectedType
                        )}`
                      )}`}
                    </Typography>
                  </div>
                )}
                <hr style={{ marginBottom: '8px', border: 'none' }} />
              </Fragment>
            )}
            <FormLabel style={{ fontSize: '0.975rem', fontWeight: 400 }}>
              {utils.getLang('smartmessaging.campaignInfos.label.outOfHoursSms')}
            </FormLabel>
            <FormControlLabel
              control={<Checkbox style={{ pointerEvents: 'auto' }} />}
              style={{ pointerEvents: 'none', margin: '8px 0px 0px 0px' }}
              // disabled={!!editedCampaign.byPass}
              disabled={!editedCampaign.isWritable}
              checked={!!editedCampaign.replay}
              onChange={() => {
                if (!editedCampaign.replay && editedCampaign.byPass) {
                  updateEditedCampaign({ replay: !editedCampaign.replay, byPass: false });
                } else {
                  updateEditedCampaign({ replay: !editedCampaign.replay });
                }
              }}
              label={
                <Typography variant="body2" style={{ color: appStyle.txtColor3 }}>
                  {utils.getLang('smartmessaging.campaignInfos.replaySms')}
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox style={{ pointerEvents: 'auto' }} />}
              disabled={!editedCampaign.isWritable}
              style={{ pointerEvents: 'none', margin: '0' }}
              checked={!!editedCampaign.byPass}
              onChange={() => {
                if (editedCampaign.byPass) updateEditedCampaign({ byPass: !editedCampaign.byPass });
                else {
                  updateEditedCampaign({ byPass: !editedCampaign.byPass, replay: false });
                }
              }}
              label={
                <Typography variant="body2" style={{ color: appStyle.txtColor3 }}>
                  {utils.getLang('smartmessaging.campaignInfos.bypassSmsHours')}
                </Typography>
              }
            />
            <hr style={{ marginBottom: '8px', border: 'none' }} />
          </div>
        </Paper>
        <div style={{ textAlign: 'right', paddingTop: '12px' }}>
          <ActionBtn
            style={{ margin: '2px' }}
            // variant=""
            cVariant="reverse"
            onClick={e => {
              e.preventDefault();
              onBack();
            }}
          >
            {utils.getLang('smartmessaging.buttonLabel.previous')}
          </ActionBtn>
          <ActionBtn
            // className={classes.button}
            style={{ margin: '2px' }}
            onClick={e => {
              e.preventDefault();
              // if (!recipeCfgLoaded)
              if (editedCampaign.isWritable) doSaveCampaign(true);
              goToEditorStep(1);
            }}
            disabled={
              !infosValidity.isValid || (!editedCampaign.requestModelTypeId && !selectedType)
            }
          >
            {utils.getLang('smartmessaging.buttonLabel.next')}
          </ActionBtn>
        </div>
      </div>
    </div>
  </Fade>
);

CampaignInfosRender.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  updateEditedCampaign: PropTypes.func.isRequired,
  wizVilleEntryActivated: PropTypes.bool.isRequired,
  wizVilTypes: PropTypes.array.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  infosValidity: PropTypes.object.isRequired,
  requestModelsByCategoryId: PropTypes.object.isRequired,
  sortedCategoryList: PropTypes.array.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  getDefaultOptin: PropTypes.func.isRequired,
  selectOptinType: PropTypes.func.isRequired,
  showWizvilleInfos: PropTypes.bool.isRequired,
};

CampaignInfosRender.defaultProps = {
  selectedType: null,
};

function CampaignInfos(props) {
  const {
    editedCampaign,
    selectedType,
    requestModelByRMTypeId,
    recipesById,
    wizVilleEntryActivated,
    wizVilleNewAboActivated,
    wizVilleCancelAboActivated,
    wizVilTypes,
    wizVilleNewAboTypes,
    wizVilleCancelAboTypes,
  } = props;
  function getDefaultOptin() {
    let selectedRM = null;

    if (editedCampaign.recipeId)
      selectedRM = requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId];
    else selectedRM = selectedType;
    return selectedRM.optinType;
  }

  function showWizvilleInfos() {
    if (
      wizVilleEntryActivated &&
      ((selectedType && wizVilTypes.indexOf(selectedType.requestModelTypeId) !== -1) ||
        (editedCampaign &&
          editedCampaign.recipeId &&
          wizVilTypes.indexOf(recipesById[editedCampaign.recipeId].requestModelTypeId) !== -1))
    )
      return true;
    if (
      wizVilleNewAboActivated &&
      ((selectedType && wizVilleNewAboTypes.indexOf(selectedType.requestModelTypeId) !== -1) ||
        (editedCampaign &&
          editedCampaign.recipeId &&
          wizVilleNewAboTypes.indexOf(recipesById[editedCampaign.recipeId].requestModelTypeId) !==
            -1))
    )
      return true;
    if (
      wizVilleCancelAboActivated &&
      ((selectedType && wizVilleCancelAboTypes.indexOf(selectedType.requestModelTypeId) !== -1) ||
        (editedCampaign &&
          editedCampaign.recipeId &&
          wizVilleCancelAboTypes.indexOf(
            recipesById[editedCampaign.recipeId].requestModelTypeId
          ) !== -1))
    )
      return true;
    return false;
  }

  function preventReplayChangeEvent() {}

  return (
    <CampaignInfosRender
      {...props}
      {...{ getDefaultOptin, preventReplayChangeEvent }}
      showWizvilleInfos={showWizvilleInfos()}
    />
  );
}

CampaignInfos.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  wizVilleEntryActivated: PropTypes.bool.isRequired,
  wizVilleNewAboActivated: PropTypes.bool.isRequired,
  wizVilleCancelAboActivated: PropTypes.bool.isRequired,
  wizVilTypes: PropTypes.array.isRequired,
  wizVilleNewAboTypes: PropTypes.array.isRequired,
  wizVilleCancelAboTypes: PropTypes.array.isRequired,
};

CampaignInfos.defaultProps = {
  selectedType: null,
};

export default withStyles(styles)(CampaignInfos);
