/* eslint-disable no-console */
import { createReducer } from '@acemarke/redux-starter-kit';
import jwtDecode from 'jwt-decode';
import qs from 'querystringify';

import apiClient from '../utils/apiClient';
// import getApiCaller from '../utils/apiClientCaller';
import { initLang } from './app';

// import globalVars from '../consts/globalVars';
import utils from '../utils/utils';

const initialState = {
  isAuthenticating: false,
  isAuthenticated: false,
  authFailed: false,
  userData: null,
  clientToken: '',
  // isNodeMapped: false,
};

export const isAuthenticated = state => state.auth.isAuthenticated;

const loginSuccess = state => ({
  ...state,
  isAuthenticated: true,
  authFailed: false,
  isAuthenticating: false,
});

const loginFailure = state => ({
  ...state,
  isAuthenticated: false,
  authFailed: true,
  isAuthenticating: false,
});

// const isNodeMapped = (state, { value }) => ({
//   ...state,
//   isNodeMapped: value,
// });

let refreshTkTOId;
/** callback is the setRefreshTokenTimeOut function */
export const refreshToken = async (loginDispatchers, clientToken, setTimeOutFn) => {
  const refreshedBearer = await apiClient.refreshToken(clientToken).then(
    resp => Promise.resolve(resp),
    err => {
      loginDispatchers.failure();
      utils.clearBearer();
      return Promise.reject(err);
    }
  );

  utils.storeBearer(refreshedBearer);
  setTimeOutFn(loginDispatchers, clientToken);
  loginDispatchers.success(refreshedBearer);
};

export const setRefreshTokenTimeOut = (loginDispatchers, clientToken) => {
  const bearer = JSON.parse(sessionStorage.getItem('sm-store')).security;
  if (refreshTkTOId) {
    clearTimeout(refreshTkTOId);
  }
  refreshTkTOId = setTimeout(() => {
    refreshToken(loginDispatchers, clientToken, setRefreshTokenTimeOut);
  }, bearer.expAt - 600000 - new Date().getTime());
  // }, 6000);
};

export const requestLogin = (match, location) => async (dispatch, getState) => {
  const { clientToken } = match.params;
  dispatch({ type: 'RECEIVE_CLIENT_TOKEN', value: clientToken });

  const loginDispatchers = {
    success(bearer) {
      dispatch({ type: 'RECEIVE_AUTH_TOKEN', value: jwtDecode(bearer.access_token) });
      dispatch({ type: 'LOGIN_SUCCESS' });
    },
    failure() {
      dispatch({ type: 'LOGIN_FAILURE' });
    },
  };

  // const apiCaller = getApiCaller(dispatch, getState);

  const { networknode, clubId } = qs.parse(location.search);

  if (clubId) {
    sessionStorage.setItem('club', clubId);
    if (networknode) {
      sessionStorage.setItem('networknode', networknode);
    } else {
      sessionStorage.removeItem('networknode');
    }
  }

  dispatch({ type: 'IS_NETWORK_NODE_MAPPED', value: !!sessionStorage.getItem('networknode') });
  dispatch({ type: 'RECEIVE_CLUB_ID', value: sessionStorage.getItem('club') }); // TODO handle missig clubID

  const logNewUser = async () => {
    const { code } = qs.parse(location.search);
    if (!sessionStorage.getItem('club')) {
      dispatch({ type: 'LOGIN_FAILURE' });
      return Promise.reject();
    }

    if (code) {
      const handleError = error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
        return Promise.reject(error);
      };

      // post authcode to oauth srv to retrieve jwt token
      const bearer = await apiClient.requestLogin(clientToken, code).then(
        resp => resp,
        error => handleError(error)
      );

      utils.storeBearer(bearer);

      setRefreshTokenTimeOut(loginDispatchers, clientToken);
      await initLang()(dispatch, getState);
      // globalVars.contexts = await apiCaller(apiClient.getContexts, [getLanguage()]).then(
      //   r => r,
      //   () => ({})
      // );

      loginDispatchers.success(bearer);
    } else {
      const redirectUri = window.encodeURIComponent(
        `${process.env.REACT_APP_PUBLIC_URL}/auth/${clientToken}`
      );

      window.location.replace(
        `${process.env.REACT_APP_OAUTH_SERVER_URL}/${clientToken}/oauth/login?client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code`
      );
    }
    return Promise.resolve();
  };

  let smStore = sessionStorage.getItem('sm-store');
  if (smStore) {
    smStore = JSON.parse(smStore);
    if (smStore.client === clientToken) {
      const refresh = smStore.security.expAt < new Date().getTime() + 600000;
      if (refresh) {
        await refreshToken(loginDispatchers, clientToken, setRefreshTokenTimeOut);
      } else {
        setRefreshTokenTimeOut(loginDispatchers, clientToken);
      }
      await initLang()(dispatch, getState);
      // globalVars.contexts = await apiCaller(apiClient.getContexts, [getLanguage()]); // .then(r => r, () => ({}));
      loginDispatchers.success(smStore.security);
    } else {
      await logNewUser(); // .catch(() => loginDispatchers.failure());
    }
  } else {
    await logNewUser(); // .catch(() => loginDispatchers.failure());
  }
};

const receiveAuthToken = (state, { value }) => ({ ...state, userData: { ...value } });
const receiveClientToken = (state, { value }) => ({ ...state, clientToken: value });

export default createReducer(initialState, {
  RECEIVE_CLIENT_TOKEN: receiveClientToken,
  RECEIVE_AUTH_TOKEN: receiveAuthToken,
  LOGIN_SUCCESS: loginSuccess,
  LOGIN_FAILURE: loginFailure,
  // IS_NETWORK_NODE_MAPPED: isNodeMapped,
});
