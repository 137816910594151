import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Toolbar,
  Typography,
  AppBar,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';

import ClearIcon from '@material-ui/icons/Cancel';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const styles = theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  uppercase: { textTransform: 'uppercase' },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor6,
    color: appStyle.txtColor3,
  },
  flex: { flex: 1 },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
});

const TitleField = ({ smj, updateSmjName, groupIsWritable }) => {
  const [isNameFocused, setIsNamedFocused] = React.useState(false);
  const [name, setName] = React.useState(smj.name);
  function isNameValid() {
    return !!name;
  }

  return !isNameFocused ? (
    <Typography
      color="inherit"
      style={{ fontSize: '1.4rem' }}
      onClick={() => {
        if (groupIsWritable) setIsNamedFocused(true);
      }}
    >
      {name}
    </Typography>
  ) : (
    <TextField
      autoFocus
      InputProps={{
        color: 'primary',
        fullWidth: true,
        style: { fontSize: '1.4rem' },
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              disabled={!isNameValid()}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                updateSmjName(name);
                setIsNamedFocused(false);
              }}
              edge="end"
            >
              <DoneIcon style={{ fill: '#b6b6b6' }} />
            </IconButton>
            <IconButton
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setName(smj.name);
                setIsNamedFocused(false);
              }}
              edge="end"
            >
              <ClearIcon style={{ fill: '#b6b6b6' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={name}
      onChange={event => setName(event.target.value)}
      // onBlur={event => {
      //   updateSmjName(name);
      //   setIsNamedFocused(false);
      // }}
    />
  );
};

TitleField.propTypes = {
  smj: PropTypes.object.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
  updateSmjName: PropTypes.func.isRequired,
};

const SMJEditorAppBar = ({ classes, smj, addCampaign, updateSmjName, groupIsWritable }) => (
  <AppBar className={classes.appBar}>
    <Toolbar>
      <div>
        <Typography color="inherit" className={classes.uppercase}>
          {utils.getLang('smartmessaging.smartjourney.editor.campaignList.title')}
        </Typography>
        <TitleField smj={smj} updateSmjName={updateSmjName} groupIsWritable={groupIsWritable} />
      </div>
      <div
        style={{
          flex: 1,
          marginLeft: '16px',
        }}
      />
      {groupIsWritable && (
        <Tooltip title={utils.getLang('smartmessaging.tooltip.campaigns.grouped.addCampaign')}>
          <IconButton
            aria-label="clear"
            onClick={e => {
              e.preventDefault();
              addCampaign();
              // clearEditedData();
              // goToSMJStep(1);
            }}
            // style={{ color: appStyle.txtColor1, backgroundColor: appStyle.bgColor10 }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  </AppBar>
);

SMJEditorAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
  smj: PropTypes.object.isRequired,
  addCampaign: PropTypes.func.isRequired,
  updateSmjName: PropTypes.func.isRequired,
};

export default withStyles(styles)(SMJEditorAppBar);
