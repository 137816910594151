import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Fade,
  FormControlLabel,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import Checkbox from 'src/components/common/Checkbox';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import massActionUtils from 'src/reducers/massAction/utils';
import { validateMassActionName, saveMassAction } from 'src/reducers/massAction';
import massActionSelectors from 'src/selectors/massAction';

import CriteriaMenu from './criteriaMenu/CriteriaMenu';
import CustomCard from 'src/components/common/Card';
import NameInput from '../ActionNameInput';
import SelectedCriteriaGroupList from './criteriaFields/SelectedCriteriaGroupList';
import { loaderActions } from 'src/reducers/loader';
import ActionBtn from 'src/components/common/ActionBtn';

const styles = () => ({
  disabledBtn: {
    color: `${appStyle.txtColor2} !important`,
    backgroundColor: appStyle.bgColor1,
    opacity: 0.7,
  },
  title: { fontSize: '0.9rem', color: appStyle.txtColor3 },
  cardHeader: { backgroundColor: appStyle.bgColor2, margin: '2px 2px 0 2px' },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column' },
  action: { marginTop: 0 },
  cardHeaderTitleRoot: { padding: '10px 15px' },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
    textAlign: 'left',
  },
  flex: { flex: 1 },
  root: {},
});

const MassActionRequestConfig = ({
  classes,
  availableCriterias,
  selectedCriterias,
  selectionValidity,
  goToStep,
  updateActionName,
  actionName,
  doValidateMassActionName,
  currentMassActionId,
  doSaveMassAction,
  setLoader,
  isWritable,
  byPass,
  replay,
  updtaBypass,
  updtaReplay,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <AppBar className={classes.appBar}>
      <Toolbar style={{ paddingLeft: '8px' }}>
        <div>
          <Typography variant="h5" color="inherit">
            {utils.getLang(`smartmessaging.massAction.filtersSelection.title`)}
            {!!currentMassActionId && ` (${currentMassActionId})`}
          </Typography>
        </div>
        <div className={classes.flex} />
        {isWritable && (
          <div
            style={{
              boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
              marginLeft: '8px',
              marginRight: '8px',
              padding: '0 8px',
            }}
          >
            <Tooltip title={utils.getLang('smartmessaging.massAction.save')}>
              <IconButton
                aria-label="clear"
                disabled={!selectionValidity.isValid}
                classes={{ disabled: classes.disabledBtn }}
                onClick={e => {
                  e.preventDefault();
                  setLoader(true);
                  doSaveMassAction().finally(() => setLoader(false));
                }}
                style={{ color: appStyle.txtColor2 }}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
    </AppBar>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        padding: '16px 24px',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <CustomCard
            title={utils.getLang(`smartmessaging.campaignInfos.label.name`)}
            contentStyle={{
              display: 'flex',
              padding: 0,
            }}
          >
            <NameInput
              style={{
                margin: '8px 0',
                flex: 1,
              }}
              required
              disabled={!isWritable}
              placeholder={utils.getLang('smartmessaging.campaignInfos.label.name')}
              value={actionName}
              onChange={event => {
                event.preventDefault();
                updateActionName(event.target.value);
              }}
            />
          </CustomCard>
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: '8px',
          }}
        >
          <CustomCard
            title={utils.getLang(`smartmessaging.campaignInfos.label.outOfHoursSms`)}
            contentStyle={{
              padding: '8px',
              paddingLeft: '0',
            }}
          >
            <div style={{ display: 'flex', textAlign: 'left' }}>
              <FormControlLabel
                control={<Checkbox style={{ pointerEvents: 'auto' }} />}
                style={{ pointerEvents: 'none', margin: 0 }}
                checked={!!replay}
                onChange={() => {
                  updtaReplay(!replay, byPass);
                }}
                label={
                  <Typography variant="body2" style={{ color: appStyle.txtColor3 }}>
                    {utils.getLang('smartmessaging.campaignInfos.replaySms')}
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox style={{ pointerEvents: 'auto' }} />}
                style={{ pointerEvents: 'none', margin: '0' }}
                checked={!!byPass}
                onChange={() => {
                  updtaBypass(!byPass, replay);
                }}
                label={
                  <Typography variant="body2" style={{ color: appStyle.txtColor3 }}>
                    {utils.getLang('smartmessaging.campaignInfos.bypassSmsHours')}
                  </Typography>
                }
              />
            </div>
          </CustomCard>
        </div>
      </div>
      <Fade in timeout={500}>
        <Grid
          container
          direction="row"
          style={{
            flexWrap: 'nowrap',
            overflow: 'hidden',
            flex: 1,
            border: `1px solid ${appStyle.bgColor2}`,
          }}
        >
          <Grid
            item
            style={{
              flexDirection: 'column',
              display: 'flex',
              width: 'fit-content',
              maxWidth: '360px',
              // borderLeft: `1px solid ${appStyle.bgColor2}`,
            }}
          >
            <div
              style={{
                overflow: 'auto',
                display: 'flex',
                flex: 1,
                height: '100%',
              }}
            >
              <Card
                elevation={0}
                style={{
                  borderRadius: 0,
                  textAlign: 'left',
                  flexDirection: 'column',
                  display: 'flex',
                  flex: 1,
                }}
              >
                <CardHeader
                  className={classes.cardHeader}
                  classes={{
                    title: classes.title,
                    action: classes.action,
                    root: classes.cardHeaderTitleRoot,
                  }}
                  title={utils.getLang('smartmessaging.massAction.availableFilters')}
                />
                <CardContent
                  // className="hoverflow"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    paddingTop: 0,
                    flex: 1,
                    marginRight: '2px',
                    overflow: 'auto',
                  }}
                >
                  {Object.keys(availableCriterias).map(groupName => (
                    <CriteriaMenu
                      key={groupName}
                      label={utils.getLang(`smartmessaging.massAction.groupFilterBy.${groupName}`)}
                      availaibleCriterias={availableCriterias[groupName]}
                      isWritable={isWritable}
                      groupName={groupName}
                      selectedCriterias={selectedCriterias[groupName]}
                    />
                  ))}
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid
            item
            style={{
              flex: 3,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              borderLeft: `1px solid ${appStyle.bgColor2}`,
            }}
          >
            <div
              style={{
                overflow: 'auto',
                display: 'flex',
                flex: 1,
                height: '100%',
              }}
            >
              <Card
                elevation={0}
                style={{
                  minWidth: '600px',
                  flex: 1,
                  textAlign: 'left',
                  borderRadius: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <CardHeader
                  className={classes.cardHeader}
                  classes={{
                    title: classes.title,
                    action: classes.action,
                    root: classes.cardHeaderTitleRoot,
                  }}
                  title={utils.getLang('smartmessaging.massAction.selectedFilters')}
                />
                <CardContent
                  // className="hoverflow"
                  style={{
                    overflow: 'auto',
                    flex: 1,
                    padding: '8px',
                  }}
                >
                  <SelectedCriteriaGroupList isWritable={isWritable} />
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Fade>
      <div style={{ textAlign: 'right', paddingTop: '12px' }}>
        <ActionBtn
          style={{ margin: '2px' }}
          // variant="contained"
          disabled={!selectionValidity.isValid}
          // classes={{ disabled: classes.disabledBtn }}
          onClick={e => {
            e.preventDefault();
            doValidateMassActionName(() => goToStep(2));
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.next')}
        </ActionBtn>
      </div>
    </div>
  </div>
);

MassActionRequestConfig.propTypes = {
  actionName: PropTypes.string.isRequired,
  currentMassActionId: PropTypes.number,
  availableCriterias: PropTypes.object.isRequired,
  selectedCriterias: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  goToStep: PropTypes.func.isRequired,
  selectionValidity: PropTypes.object.isRequired,
  updateActionName: PropTypes.func.isRequired,
  doValidateMassActionName: PropTypes.func.isRequired,
  doSaveMassAction: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  byPass: PropTypes.bool,
  replay: PropTypes.bool,
  updtaBypass: PropTypes.func.isRequired,
  updtaReplay: PropTypes.func.isRequired,
};

MassActionRequestConfig.defaultProps = {
  byPass: null,
  replay: null,
  currentMassActionId: null,
};

const mapStateToProps = state => ({
  actionName: state.massAction.currentName,
  availableCriterias: state.massAction.availableCriterias,
  currentMassActionId: state.massAction.currentMassActionId,
  replay: state.massAction.currentReplay,
  byPass: state.massAction.currentByPass,
  selectionValidity: massActionUtils.selectionIsValid(state.massAction.selectedCriterias),
  selectedCriterias: massActionSelectors.getSelectedCriterias(state.massAction),
});
const actionCreators = {
  doSaveMassAction: saveMassAction,
  updateActionName: name => ({ type: 'UPDATE_ACTION_NAME', value: name }),
  doValidateMassActionName: validateMassActionName,
  setLoader: visible => loaderActions.setLoader(visible),
  updtaReplay: (replay, byPass) => ({ type: 'UPDATE_REPLAY', value: { replay, byPass } }),
  updtaBypass: (byPass, replay) => ({ type: 'UPDATE_BYPASS', value: { replay, byPass } }),
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(MassActionRequestConfig));
